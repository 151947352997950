import { IonToast } from '@ionic/react';
import useMessage from 'hooks/useMessage';
import React from 'react';

const MessagesDisplay = () => {
  const { message, display, clearMessage } = useMessage();
  return (
    <div>
      <IonToast
        color={message.color || 'danger'}
        isOpen={display}
        message={message.content}
        duration={5000}
        onDidDismiss={() => clearMessage()}
        position="top"
      />
    </div>
  );
};

export default MessagesDisplay;
