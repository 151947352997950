import { ApolloProvider } from '@apollo/react-hooks';
import { Plugins } from '@capacitor/core';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import * as Sentry from '@sentry/browser';
import ProtectedRoute from 'containers/ProtectedRoute';
import Auth from 'pages/Auth';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Redirect, Route } from 'react-router-dom';
import { StoreService } from 'services/StoreService';

import { version } from '../package.json';
import App from './App';

const { SplashScreen } = Plugins;

const { NODE_ENV, REACT_APP_SENTRY_DSN } = process.env;

if (NODE_ENV !== 'development' && REACT_APP_SENTRY_DSN) {
  // When not initialized, the Sentry SDK methods (ie. `Sentry.captureEvent`)
  // can be safely called but do not POST to the Sentry service.
  Sentry.init({ dsn: REACT_APP_SENTRY_DSN, environment: NODE_ENV });
}

const Root = () => {
  const store = new StoreService(version);
  const client = store.getClient();

  const deviceReady = () => {
    // tslint:disable-next-line: no-console
    console.log('APP READY');
    SplashScreen.hide();
  };

  useEffect(() => {
    document.addEventListener('deviceready', deviceReady);
    return () => {
      document.removeEventListener('deviceready', deviceReady);
    };
  });

  return (
    <ApolloProvider client={client}>
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <ProtectedRoute path="/app" component={App} />
            <Route path="/auth" component={Auth} />
            <Redirect exact from="/" to="/app/home" />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </ApolloProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
