import { useApolloClient, useQuery } from '@apollo/react-hooks';
import * as Sentry from '@sentry/browser';
import gql from 'graphql-tag';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';

export const ACCESS_TOKEN = gql`
  query AccessToken {
    accessToken @client
  }
`;

export const parseJwt = (token: string) => {
  try {
    // Get Token Header
    const base64HeaderUrl = token.split('.')[0];
    const base64Header = base64HeaderUrl.replace('-', '+').replace('_', '/');
    const headerData = JSON.parse(window.atob(base64Header));

    // Get Token payload and date's
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const dataJWT = JSON.parse(window.atob(base64));
    dataJWT.header = headerData;

    return dataJWT;
  } catch (err) {
    return false;
  }
};

/**
 * Validate token format and expiration
 * @param accessToken Non-parsed token to validate
 * @param checkAgainst Time to validate expirating against. Seconds since 1 Jan 1970.
 * @returns `true` if token is valid and does not expire prior to `checkAgainst`, otherwise `false`.
 */
const isAccessTokenValid = (accessToken: string, checkAgainst: number): boolean => {
  const parsed = parseJwt(accessToken);

  if (!parsed) {
    return false;
  }

  if (parsed.exp > checkAgainst) {
    return true;
  }

  return false;
};

/** Calc end of today as seconds since 1 January 1970 */
const endOfToday = (): number => {
  const today = moment().endOf('day');
  return today.valueOf() / 1000;
};

export const useAccessToken = () => {
  const client = useApolloClient();
  const { data, loading } = useQuery(ACCESS_TOKEN, { displayName: 'useAccessToken' });
  const accessToken = data && data.accessToken;
  const tokenCheckAgainst = endOfToday();

  // A user is authenticated if they have a valid token and the token will not expire prior to the end of today.
  // Using end of day to:
  // 1. Give some buffer so token is less likely to expire during a session.
  // 2. Allow us to memoize the check. We only need the granularity of 1 day.
  const isAuthenticated = useMemo(() => isAccessTokenValid(accessToken, tokenCheckAgainst), [
    accessToken,
    tokenCheckAgainst
  ]);

  // Set Sentry user on authentication changes
  // Setting the Sentry user will allow the user ID and name to be included with any logs
  useEffect(() => {
    if (!isAuthenticated) {
      Sentry.configureScope(scope => scope.setUser(null));
      return;
    }

    const parsed = parseJwt(accessToken);
    Sentry.setUser({
      id: parsed.id,
      username: `${parsed.firstName} ${parsed.lastName}`
    });
  }, [isAuthenticated, accessToken]);

  const saveAccessToken = async (t: string) => client.writeData({ data: { accessToken: t } });

  const logout = useCallback(async () => client.resetStore(), [client]);

  return {
    loading,
    saveAccessToken,
    isAuthenticated,
    accessToken,
    logout
  };
};

export default useAccessToken;
