import { IonContent, IonLoading, IonPage } from '@ionic/react';
import EmailInput from 'components/EmailInput';
import Form from 'components/Form';
import MessagesDisplay from 'containers/MessagesDisplay';
import useAuthFlow, { ChallengeMethods } from 'hooks/useAuthFlow';
import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

const EmailChallenge: React.FC<RouteComponentProps> = ({ location: { search } }) => {
  const params = qs.parse(search, { ignoreQueryPrefix: true });
  const { switchMethod, startChallenge, isValidInput, data, loading } = useAuthFlow();
  const { recipient, reissue } = data;
  const [email, setEmail] = useState(params.email || recipient || '');
  const [validated, setValidated] = useState<boolean>(false);

  const checkValidation = useCallback(
    () => email && setValidated(isValidInput(ChallengeMethods.Email, email)),
    [email, setValidated, isValidInput]
  );
  const handleNext = useCallback(() => {
    if (validated && email) {
      startChallenge({ method: ChallengeMethods.Email, recipient: email, reissue });
    }
  }, [validated, email, reissue, startChallenge]);

  /** Responsible for checking the validity of email input */
  useEffect(() => {
    checkValidation();
  }, [checkValidation]);

  const subtitle = (
    <span>
      Provide your <strong>email address</strong> to login or to begin a new registration.
    </span>
  );

  const buttons = [
    {
      text: 'Next',
      handler: handleNext,
      isDisabled: !validated
    },
    {
      text: 'Login with phone number instead?',
      color: 'tertiary',
      primary: false,
      handler: () => switchMethod(ChallengeMethods.Phone)
    }
  ];

  return (
    <IonPage>
      <IonContent color="background">
        <IonLoading isOpen={loading} duration={10000} spinner="crescent" message="One Moment..." />
        <MessagesDisplay />
        <Form title="Welcome to P3 Works" subtitle={subtitle} buttons={buttons}>
          <EmailInput value={email} onChange={setEmail} clearInput autofocus />
        </Form>
      </IonContent>
    </IonPage>
  );
};

export default EmailChallenge;
