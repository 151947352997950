import useAccessToken from 'hooks/useAccessToken';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

const ProtectedRoute: React.FC<RouteProps & { component: any }> = ({
  component: Component,
  ...rest
}) => {
  const { isAuthenticated, loading } = useAccessToken();

  return (
    <Route
      {...rest}
      render={props => (
        !loading && isAuthenticated === true
        ? <Component {...props} />
        : <Redirect to="/auth" />)
      }
    />
  );
};

export default ProtectedRoute;
