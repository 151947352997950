import { IonLabel, IonSegment, IonSegmentButton, IonToolbar } from '@ionic/react';
import { RequestFilterTypes } from 'hooks/useRequests';
import React, { useEffect, useState } from 'react';

interface IRequestsFilter {
  applyFilter: (type: RequestFilterTypes) => any;
}

const RequestsFilter: React.FC<IRequestsFilter> = ({ applyFilter }) => {
  const [filterType, setFilterType] = useState<RequestFilterTypes>(RequestFilterTypes.Active);

  useEffect(() => {
    applyFilter(filterType);
  }, [filterType]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <IonToolbar>
      <IonSegment onIonChange={(e: any) => setFilterType(e.detail.value)} value={filterType}>
        <IonSegmentButton value={RequestFilterTypes.Active}>
          <IonLabel color="success">Active</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value={RequestFilterTypes.Inactive}>
          <IonLabel color="danger">Inactive</IonLabel>
        </IonSegmentButton>
      </IonSegment>
    </IonToolbar>
  );
};

export default RequestsFilter;
