import { IonAvatar, IonItem, IonLabel, IonSkeletonText } from '@ionic/react';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  label: {
    fontWeight: 'bold !important',
    color: 'var(--ion-color-primary) !important'
  },
  timestamp: {
    color: 'var(--ion-color-dark) !important'
  },
  icon: {
    'margin-inline-end': '5px !important',
    width: 34,
    height: 34
  }
});

const StatusItemSkeleton = () => {
  const classes = useStyles();

  return (
    <IonItem lines="none">
      <IonAvatar slot="start" className={classes.icon}>
        <IonSkeletonText animated />
      </IonAvatar>
      <IonLabel className="ion-text-wrap">
        <h4 className={classes.label}>
          {' '}
          <IonSkeletonText animated={true} style={{ width: '60%' }} />{' '}
        </h4>
        <p className={classes.timestamp}>
          {' '}
          <IonSkeletonText animated={true} style={{ width: '40%' }} />{' '}
        </p>
      </IonLabel>
    </IonItem>
  );
};

export default StatusItemSkeleton;
