import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { parseJwt, useAccessToken } from 'hooks/useAccessToken';

export const GET_USER = gql`
  query GetUser($userId: ID!) {
    user(id: $userId) {
      id
      firstName
      lastName
    }
  }
`;

export interface User {
  id: string;
  firstName: string;
  lastName: string;
}

export const useUserInfo = () => {
  const { accessToken } = useAccessToken();
  const decoded = parseJwt(accessToken);
  const { id } = decoded;
  const { data, refetch, loading } = useQuery(GET_USER, { variables: { userId: id }, pollInterval: 300000 });
  const user: User = data && data.user ? data.user : {};

  return {
    refetch,
    loading,
    user
  };
};

export default useUserInfo;
