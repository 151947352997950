import { IonAvatar, IonButton, IonIcon } from '@ionic/react';
import emptyAvatar from 'assets/images/empty-avatar.svg';
import precinctAvatar from 'assets/images/precinct-avatar.png';
import ActivityCard from 'components/ActivityCard';
import PhotoImage from 'components/PhotoImage';
import StaticMap from 'components/StaticMap';
import MessagesDisplay from 'containers/MessagesDisplay';
import { Comment } from 'hooks/useComments';
import useMessage from 'hooks/useMessage';
import { Photo, usePhotoGallery } from 'hooks/usePhotoGallery';
import { Request } from 'hooks/useRequests';
import { useUserInfo } from 'hooks/useUserInfo';
import { trash } from 'ionicons/icons';
import moment from 'moment';
import React, { ReactNode } from 'react';

interface IAuthorGraphic {
  author: string;
  owner: string;
  isComment?: boolean;
}

const AuthorGraphic = ({ author, owner }: IAuthorGraphic) => {
  const isPrecinct = author !== owner;
  return (
    <IonAvatar slot="start">
      <img src={isPrecinct ? precinctAvatar : emptyAvatar} alt="Author Graphic" />
    </IonAvatar>
  );
};

/** This idea is something I want to move to the backend so that Activity is queried based on events */
interface Activity {
  timestamp: number;
  content: any;
  title: string;
  subtitle?: string;
  graphic?: ReactNode;
  noSpace?: boolean;
}

export interface RequestActivityFeedPros {
  request: Request;
  comments: Comment[];
  photos: Photo[];
}

const RequestActivityFeed: React.FC<RequestActivityFeedPros> = ({ request, comments, photos }) => {
  const { user } = useUserInfo();
  const { setMessage } = useMessage();
  const { selectPhoto, removePhoto } = usePhotoGallery({ requestId: request.id });

  if (!request) {
    setMessage({
      code: 'MissingRequest',
      color: 'danger',
      content: 'We are having trouble loading this request right now.'
    });
    return <MessagesDisplay />;
  }

  const displayName =
    user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : `You`;

  const activities: Activity[] = [];
  if (comments && comments.length > 0) {
    for (const comment of comments) {
      const isPrecinct = comment.author !== request.createdBy;

      activities.push({
        title: isPrecinct ? 'Precinct 3' : `${displayName}`,
        subtitle: moment(Number(comment.createdAt)).fromNow(),
        timestamp: comment.createdAt,
        content: <p style={{ fontSize: 13, whiteSpace: 'pre-wrap' }}>{comment.content}</p>,
        graphic: <AuthorGraphic author={comment.author} owner={request.createdBy} />
      });
    }
  }

  if (photos && photos.length > 0) {
    for (const photo of photos) {
      const isPrecinct = photo.uploadedBy !== request.createdBy;

      activities.push({
        title: isPrecinct ? 'Precinct 3' : `${displayName}`,
        subtitle: moment(Number(photo.uploadedAt)).fromNow(),
        timestamp: photo.uploadedAt,
        content: <PhotoImage src={photo.src} id={photo.id} />,
        graphic: (
          <>
            <AuthorGraphic author={photo.uploadedBy} owner={request.createdBy} />
            <IonButton
              onClick={() => removePhoto(photo.id)}
              fill="clear"
              slot="end"
              color="secondary"
            >
              <IonIcon slot="icon-only" icon={trash} />
            </IonButton>
          </>
        ),
        noSpace: true
      });
    }
  } else {
    activities.push({
      title: 'Upload a photo',
      subtitle: 'Please help our precinct staff address your request by uploading some photos.',
      timestamp: moment().valueOf(),
      content: (
        <IonButton onClick={selectPhoto} expand="full" color="secondary">
          Upload Photo
        </IonButton>
      )
    });
  }

  // Any static items here, just give them a current timestamp so they are the newest items.
  if (request && request.id) {
    activities.push({
      title: `${request.category} Issue`,
      subtitle: request.address,
      timestamp: moment().valueOf(),
      noSpace: true,
      content: (
        <div>
          <p className="ion-padding">{request.description}</p>
          <StaticMap
            latitude={request.latitude}
            longitude={request.longitude}
            height={150}
            zoom={14}
          />
        </div>
      )
    });
  }

  const sort = (a: any, b: any) => (a.timestamp < b.timestamp ? 1 : -1);

  return (
    <>
      {activities.sort(sort).map((activity: Activity, i: number) => (
        <ActivityCard key={i} {...activity} />
      ))}
    </>
  );
};
export default RequestActivityFeed;
