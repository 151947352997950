import { IonCol, IonContent, IonGrid, IonHeader, IonImg, IonPage, IonRow } from '@ionic/react';
import { useAppUrlOpen } from '@ionic/react-hooks/app';
import appSeal from 'assets/images/app-seal.png';
import AlertBanner from 'containers/AlertBanner';
import RequestStatsCard from 'containers/RequestStatsCard';
import SelectCategoryCard from 'containers/SelectCategoryCard';
import React, { useCallback, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { RouteComponentProps } from 'react-router-dom';

const useStyles = createUseStyles({
  header: {
    // header is mode="ios", so styling is always applied
    // md mode header uses background image instead of box-shadow
    // easier to style by setting mode to ios and remove box-shadow
    '.ios &': {
      boxShadow: 'none'
    }
  },
  appSeal: {
    height: '200px',
    objectFit: 'cover'
  },
  cards: {
    justifyContent: 'center'
  }
});

const Home: React.FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const { appUrlOpen } = useAppUrlOpen();

  const handleDeepLink = useCallback(() => {
    if (appUrlOpen) {
      const [, path] = appUrlOpen.split('//');
      history.push(`/${path}`);
    }
  }, [appUrlOpen, history]);

  useEffect(() => {
    handleDeepLink();
  }, [handleDeepLink]);

  return (
    <IonPage>
      <IonHeader className={classes.header} mode="ios">
        <AlertBanner />
      </IonHeader>
      <IonContent color="background" forceOverscroll>
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <IonImg src={appSeal} className={classes.appSeal} />
            </IonCol>
          </IonRow>
          <IonRow className={classes.cards}>
            <IonCol size="12" sizeMd="6" sizeLg="4">
              <SelectCategoryCard />
              <RequestStatsCard />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Home;
