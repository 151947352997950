import { JSX } from '@ionic/core';
import { IonInput, IonItem, IonLabel } from '@ionic/react';
import React from 'react';
import { Rifm } from 'rifm';

interface TextInputProps {
  label?: string;
  value: string;
  onChange: (arg0: any) => void;
}

const TextInput: React.FC<TextInputProps & JSX.IonInput> = ({
  label,
  onChange,
  value,
  ...inputProps
}) => {

  return (
    <Rifm
      accept={/./g}
      replace={v => v.slice(0, 1).toUpperCase() + v.slice(1).toLowerCase()}
      format={v => v}
      value={value}
      onChange={onChange}
    >
      {({ onChange: x, value: y }: any) => (
        <IonItem lines="none">
          {label && <IonLabel position="stacked" color="dark">{label}</IonLabel>}
          <IonInput
            {...inputProps}
            inputmode="text"
            type="text"
            value={y}
            onIonChange={x}
            placeholder=""
          />
        </IonItem>
      )}
    </Rifm>
  );
};

export default TextInput;
