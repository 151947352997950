import {
  IonContent,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import RequestsFilter from 'components/RequestsFilter';
import RequestsList from 'components/RequestsList';
import { Request, useRequests } from 'hooks/useRequests';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

const YourRequests: React.FC<RouteComponentProps> = ({ history, match }) => {
  const { url } = match;
  const { requests, loading, refetch, applyFilter } = useRequests();

  const refresh = (event: CustomEvent<any>) => {
    refetch()
      .then(() => event.detail.complete())
      .catch(() => event.detail.complete());
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="white">
          <IonTitle color="primary">Requests</IonTitle>
        </IonToolbar>
        <RequestsFilter applyFilter={applyFilter} />
      </IonHeader>
      <IonContent color="background">
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent />
        </IonRefresher>
        <RequestsList
          requests={requests}
          loading={loading}
          onItemSelect={(request: Request) => history.push(`${url}/details/${request.id}`)}
        />
      </IonContent>
    </IonPage>
  );
};

export default YourRequests;
