import { IonCol, IonGrid, IonRow } from '@ionic/react';
import noRequestsGraphic from 'assets/images/no-requests.svg';
import EmptyList from 'components/EmptyList';
import RequestItem from 'components/RequestItem';
import RequestItemSkeleton from 'components/RequestItemSkeleton';
import { Request } from 'hooks/useRequests';
import React from 'react';

interface IRequestList {
  requests: Request[];
  loading?: boolean;
  onItemSelect: (item: Request) => any;
}

const RequestsList: React.FC<IRequestList> = ({ requests, loading = false, onItemSelect }) => {
  if (loading) {
    const items = Array(12)
      .fill(null)
      .map((_, i) => i);
    return (
      <IonGrid>
        <IonRow className="ion-justify-content-start">
          {items.map(i => (
            <IonCol key={i} size="12" sizeMd="6" sizeLg="4">
              <RequestItemSkeleton />
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    );
  }

  if (requests.length === 0)
    return (
      <EmptyList
        title="It feels a little bit empty"
        subtitle="Please change the filter or submit your first request."
        graphic={noRequestsGraphic}
      />
    );

  return (
    <IonGrid>
      <IonRow className="ion-justify-content-start">
        {requests.map((request: any) => (
          <IonCol key={request.id} size="12" sizeMd="6" sizeLg="4">
            <RequestItem onClick={() => onItemSelect(request)} {...request} />
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
};

export default RequestsList;
