import 'mapbox-gl/dist/mapbox-gl.css';

import mapboxgl from 'mapbox-gl';
import React, { useCallback, useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';

const { REACT_APP_MAPBOX_TOKEN = '' } = process.env;

mapboxgl.accessToken = REACT_APP_MAPBOX_TOKEN;

interface IInteractiveMap {
  zoom?: number;
  height?: string | number;
  width?: string | number;
  onSelectedLocation?: (longitude: number, latitude: number) => void;
  onReady?: () => void;
  moveable?: boolean;
  enableGeolocate?: boolean;
  popupText?: string;
  latitude?: number;
  longitude?: number;
}

const useStyles = createUseStyles({
  '@global': {
    '.mapboxgl-ctrl-top-right': {
      '& > .mapboxgl-ctrl-geocoder': {
        width: `calc(100vw - 20px)`
      }
    },
    '.map-popup': {
      textAlign: 'center'
    }
  }
});

const InteractiveMap = ({
  zoom = 16,
  height = 200,
  width = '100%',
  onSelectedLocation,
  onReady,
  moveable = false,
  enableGeolocate = false,
  popupText = 'To confirm location, search an address, use current location or move the marker.',
  latitude = 30.1268,
  longitude = -95.4425
}: IInteractiveMap) => {
  useStyles();
  const mapContainer: any = useRef();
  const map: any = useRef();
  const marker: any = useRef();
  const popup: any = useRef();

  // useEffect onLocationChange - run everytime the location object changes from props
  const onLocationChange = useCallback(() => {
    // Update the popup address when location changes
    popup.current.setHTML(`<div>${popupText}</div>`);
    // Sets map to center of location object - triggers onMove event
    map.current.flyTo({ center: [longitude, latitude] });
    // Update the map size
    map.current.resize();
  }, [popupText, longitude, latitude]);

  // Event onMove - sets marker to current map center anytime map moves
  const onMove = () => {
    const currentCenter = map.current.getCenter();
    marker.current.setLngLat(currentCenter);
  };

  // Event onDragEnd - updates selected location from marker position
  const onDragEnd = async () => {
    const { lng, lat } = marker.current.getLngLat();
    if (onSelectedLocation) {
      onSelectedLocation(lng, lat);
    }
  };

  // Event onLoad - runs on map load
  const onLoad = () => {
    // Attach popup to marker, add marker to map and show popup
    marker.current
      .setPopup(popup.current)
      .addTo(map.current)
      .togglePopup();
    // Resize the map to make sure it fits right
    map.current.resize();

    if (onReady) {
      onReady();
    }
  };

  // Evemt keyboardDidHide - runs when keyboard hides
  const onKeyboardHide = () => {
    // Resize the map because the view likely changed too
    map.current.resize();
  };

  // onUnmount - cleanup logic for unmount
  const onUnmount = () => {
    // Remove map events to prevent duplication
    map.current.off('load', onLoad);
    map.current.off('move', onMove);
    map.current.off('dragend', onDragEnd);

    window.removeEventListener('keyboardDidHide', onKeyboardHide);

    // Remove the map to prevent more than one each mount
    map.current.remove();
  };

  // onMount - initialization logic
  const onMount = () => {
    if (!mapContainer.current) {
      throw new Error('Ref to map container element must be assigned before rendering');
    }

    // Map Initialize
    map.current = new mapboxgl.Map({
      container: (mapContainer.current as unknown) as HTMLElement,
      style: 'mapbox://styles/mapbox/light-v9',
      interactive: moveable,
      attributionControl: false,
      center: [longitude, latitude],
      zoom,
      scrollZoom: { around: 'center' } as any, // Incorrectly typed in @types/mapbox-gl
      touchZoomRotate: { arount: 'center' } as any // Incorrectly typed in @types/mapbox-gl
    });

    // Marker Initialize
    marker.current = new mapboxgl.Marker({
      color: '#17243d'
    });

    // Popup Initialize
    popup.current = new mapboxgl.Popup({
      className: 'map-popup',
      closeButton: false,
      closeOnClick: false,
      maxWidth: '200px'
    });

    if (enableGeolocate) {
      map.current.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          fitBoundsOptions: { maxZoom: 16 },
          showUserLocation: false
        })
      );
    }

    // Map Events
    map.current.on('load', onLoad);
    map.current.on('move', onMove);
    map.current.on('dragend', onDragEnd);

    window.addEventListener('keyboardDidHide', onKeyboardHide);

    return onUnmount;
  };

  // Initialize onMount once
  useEffect(onMount, []);

  // Watches location change
  useEffect(onLocationChange, [onLocationChange]);

  return <div ref={el => (mapContainer.current = el)} style={{ height, width }} />;
};

export default InteractiveMap;
