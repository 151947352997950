import { IonButton, IonCard, IonCardContent } from '@ionic/react';
import useAccessToken from 'hooks/useAccessToken';
import React from 'react';

const DeviceSettings: React.FC = () => {
  const { logout } = useAccessToken();

  return (
    <IonCard>
      <IonCardContent>
        <IonButton
          fill="outline"
          expand="block"
          color="primary"
          className="ion-no-margin"
          onClick={logout}
        >
          Sign Out
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};

export default DeviceSettings;
