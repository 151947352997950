import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';

import { RequestCategory } from './useCategories';
import useMessage from './useMessage';
import { RequestStatus } from './useStatus';

export const GET_REQUESTS = gql`
  query GetRequests {
    requests(currentUserOnly: true) {
      nodes {
        id
        category
        description
        status
        modifiedAt
        createdBy
        location {
          address
        }
        geometry {
          center {
            latitude
            longitude
          }
        }
      }
    }
  }
`;

export const CREATE_REQUEST = gql`
  mutation createRequest(
    $category: RequestCategory!
    $description: String!
    $latitude: Float!
    $longitude: Float!
    $address: String!
  ) {
    createRequest(
      input: {
        category: $category
        description: $description
        latitude: $latitude
        longitude: $longitude
        address: $address
      }
    ) {
      request {
        id
        category
        description
        status
        modifiedAt
        createdBy
        location {
          address
        }
        geometry {
          center {
            latitude
            longitude
          }
        }
      }
    }
  }
`;

export enum RequestFilterTypes {
  Active = 'active',
  Inactive = 'inactive'
}

export interface CollectionQuery {
  createdBy?: string;
  statuses?: RequestStatus[];
}

export interface NewRequest {
  category?: RequestCategory;
  description?: string;
  address?: string;
  latitude?: number;
  longitude?: number;
}

export interface Request {
  id: string;
  category: RequestCategory;
  description: string;
  status: RequestStatus;
  modifiedAt: number;
  address: string;
  latitude: number;
  longitude: number;
  createdBy: string;
}

// Helper functions for filterBy
const isOwner = (record: any, owner: string) => owner && owner === record.createdBy;
const isAnyStatus = (record: any, statuses: RequestStatus[]) =>
  statuses && statuses.some((status: RequestStatus) => status === record.status);

// Filter a collection using collection query
export const filterBy = (coll: any[], q?: CollectionQuery) => {
  // Default filter returns NO records if matches not found and q is passed
  let filter = q ? (_: any) => false : (_: any) => true;
  // Make this filter an AND condition - more than 1 param found
  const conditions: any[] = [];
  if (q && q.createdBy) conditions.push((item: any) => q.createdBy && isOwner(item, q.createdBy));
  if (q && q.statuses)
    conditions.push(
      (item: any) => q.statuses && q.statuses.length > 0 && isAnyStatus(item, q.statuses)
    );
  filter = (item: any) => conditions.every((condition: any) => condition(item));
  // When applying queries, explicity allow but deny by default
  return coll.filter(filter);
};

// Sorts records by modifiedAt timestamp
const sortBy = (a: any, b: any) => (a.modifiedAt < b.modifiedAt ? 1 : -1);

// Maps a server request to a local request
const mapRequest = ({ geometry, location, ...rest }: any): Request => {
  const { latitude, longitude } = (geometry && geometry.center) || {
    latitude: 30.1268,
    longitude: -95.4425
  };
  const { address } = location || { address: 'Unknown Address ' };
  return { ...rest, latitude, longitude, address };
};

export const useRequests = () => {
  const [query, setQuery] = useState<CollectionQuery>();
  const [requests, setRequests] = useState<Request[]>([]);
  const { setMessage } = useMessage();

  const [createRequest, { loading: createRequestLoading }] = useMutation(CREATE_REQUEST, {
    update: (cache, { data: mutationData }) => {
      // Update cache for use by RequestStats and YourRequests
      const queryData: any = cache.readQuery({ query: GET_REQUESTS });
      queryData.requests.nodes.push(mutationData.createRequest.request);
      cache.writeQuery({ query: GET_REQUESTS, data: queryData });
    }
  });

  const { loading: getRequestsLoading, data, refetch } = useQuery(GET_REQUESTS, {
    pollInterval: 300000,
    fetchPolicy: 'cache-and-network',
    onError: (err: any) => {
      setMessage({
        code: err.code || 'RequestsFailed',
        color: err.color || 'danger',
        content: 'We are having trouble loading this right now.'
      });
    }
  });

  const findRequestById = (requestId: string) => {
    return requests.find((request: Request) => request.id === requestId);
  };

  const applyFilter = (type: RequestFilterTypes) => {
    if (type === RequestFilterTypes.Active) {
      setQuery({
        statuses: [RequestStatus.Approved, RequestStatus.Submitted]
      });
    }

    if (type === RequestFilterTypes.Inactive) {
      setQuery({
        statuses: [RequestStatus.Denied, RequestStatus.Completed]
      });
    }
  };

  const submitRequest = async (input: NewRequest): Promise<Request> => {
    try {
      const { data: record } = await createRequest({ variables: input });
      setMessage({
        content: 'Thanks for your submittal. Add some photos to help us better address this issue.',
        color: 'success'
      });
      return record.createRequest.request;
    } catch (err) {
      setMessage({
        content: err.message || 'Unable to submit this request at this time.',
        color: 'danger'
      });

      throw err;
    }
  };

  useEffect(() => {
    if (!getRequestsLoading && data && data.requests && data.requests.nodes) {
      const records: Request[] = data.requests.nodes.map(mapRequest);
      const items = filterBy(records, query).sort(sortBy);
      setRequests(items);
    }
  }, [data, query, getRequestsLoading]);

  return {
    loading: getRequestsLoading || createRequestLoading,
    getRequestsLoading,
    createRequestLoading,
    refetch,
    requests,
    findRequestById,
    applyFilter,
    submitRequest
  };
};

export default useRequests;
