import { JSX } from '@ionic/core';
import { IonInput, IonItem, IonLabel } from '@ionic/react';
import React from 'react';
import { Rifm } from 'rifm';

interface EmailInputProps {
  value: string;
  onChange: (arg0: any) => void;
  label?: string;
}

const EmailInput: React.FC<EmailInputProps & JSX.IonInput> = ({
  onChange,
  value,
  label,
  ...inputProps
}) => {

  return (
    <Rifm
      accept={/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/g}
      format={v => v}
      value={value}
      onChange={onChange}
    >
      {({ onChange: x, value: y}: any) => (
        <IonItem lines="none">
            {label && <IonLabel position="stacked" color="dark">{label}</IonLabel>}
            <IonInput
              {...inputProps}
              inputmode="email"
              type="email"
              value={y}
              onIonChange={x}
              placeholder="first.last@email.com"
            />
        </IonItem>
      )}
    </Rifm>
  );
};

export default EmailInput;
