import { IonButton, IonCol, IonGrid, IonRow  } from '@ionic/react';
import React from 'react';
import { createUseStyles } from 'react-jss';

type IFormButton = {
  text: string;
  color?: string;
  handler?: any;
  isDisabled?: boolean;
  primary?: boolean;
};

type IFormProps = {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  children: any;
  buttons?: IFormButton[]
};

const useStyles = createUseStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%'
  },
  header: {
    padding: [0, 20],
    textAlign: 'center'
  },
  title: {
    fontSize: '1.25em',
    fontWeight: 'bold',
    color: 'var(--ion-color-dark)'
  },
  subtitle: {
    fontSize: '0.825em',
    color: 'var(--ion-color-dark)'
  },
  inputs: {
    margin: 20,
    '& ion-input:only-child': {
      border: 'none'
    }
  },
  footer: {
    margin: [0, 20]
  }
});

const Form = ({
  title,
  subtitle,
  children,
  buttons = []
}: IFormProps) => {
  const classes = useStyles();

  return (
    <IonGrid className={classes.content}>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12" sizeMd="6" sizeLg="4">
        <div className={classes.header}>
          <h2 className={classes.title}>{title}</h2>
          <p className={classes.subtitle}>{subtitle}</p>
        </div>
        <div className={classes.inputs}>{children}</div>
        <div className={classes.footer}>
          {buttons.map(({ text, color = 'primary', isDisabled = false, primary = true, handler }: IFormButton, i: number) => (
            <IonButton
              key={i}
              size={primary ? 'default' : 'small'}
              fill={primary ? 'solid' : 'clear'}
              expand="full"
              color={color}
              className={primary ? 'ion-no-margin' : 'ion-margin'}
              onClick={handler}
              disabled={isDisabled}
            >
              {text}
            </IonButton>
          ))}
        </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Form;
