import { alert, arrowRedoCircle, checkmarkCircle, closeCircle, syncCircle } from 'ionicons/icons';

export type IStatus = {
  color: string;
  icon: string;
  label: string;
};

export enum RequestStatus {
  Submitted = 'Submitted',
  Approved = 'Approved',
  Denied = 'Denied',
  Completed = 'Completed'
}

export const useStatus = (status: RequestStatus): IStatus => {
  switch (status) {
    case RequestStatus.Submitted:
      return {
        color: 'special',
        icon: arrowRedoCircle,
        label: 'Submitted'
      };
    case RequestStatus.Approved:
      return {
        color: 'warning',
        icon: syncCircle,
        label: 'In Progress'
      };
    case RequestStatus.Denied:
      return {
        color: 'danger',
        icon: closeCircle,
        label: 'Denied'
      };
    case RequestStatus.Completed:
      return {
        color: 'success',
        icon: checkmarkCircle,
        label: 'Completed'
      };
    default:
      return {
        color: 'dark',
        icon: alert,
        label: 'Unknown Status'
      };
  }
};
