import {
  IonAvatar,
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonSkeletonText
} from '@ionic/react';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface IActivityFeedSkeleton {
  times?: number;
}

const useStyles = createUseStyles({
  title: {
    fontWeight: 'bold !important',
    color: 'var(--ion-color-primary) !important'
  },
  subtitle: {
    color: 'var(--ion-color-dark) !important'
  },
  item: {
    borderBottom: '1px solid var(--ion-color-light)'
  },
  icon: {
    'margin-inline-end': '5px !important',
    width: 34,
    height: 34
  }
});

const ActivityFeedSkeleton: React.FC<IActivityFeedSkeleton> = ({ times = 4 }) => {
  const classes = useStyles();

  const items = Array(times)
    .fill(null)
    .map((_, i) => i);
  return (
    <>
      {items.map((i: number) => (
        <IonCard key={i}>
          <IonItem lines="none" className={classes.item}>
            <IonAvatar slot="start" className={classes.icon}>
              <IonSkeletonText animated />
            </IonAvatar>
            <IonLabel className="ion-text-wrap">
              <h4 className={classes.title}>
                {' '}
                <IonSkeletonText animated={true} style={{ width: '60%' }} />{' '}
              </h4>
              <p className={classes.subtitle}>
                {' '}
                <IonSkeletonText animated={true} style={{ width: '40%' }} />{' '}
              </p>
            </IonLabel>
          </IonItem>
          <IonCardContent>
            <IonSkeletonText animated style={{ height: '150px' }} />
          </IonCardContent>
        </IonCard>
      ))}
    </>
  );
};

export default ActivityFeedSkeleton;
