import { IonRouterOutlet, isPlatform } from '@ionic/react';
import useAccessToken from 'hooks/useAccessToken';
import useAuthFlow, { AuthSteps, ChallengeMethods } from 'hooks/useAuthFlow';
import React, { useCallback, useEffect } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

import EmailChallenge from './EmailChallenge';
import PhoneChallenge from './PhoneChallenge';
import RegisterUser from './RegisterUser';
import VerifyChallenge from './VerifyChallenge';

const Auth: React.FC<RouteComponentProps> = ({ match, history, location }) => {
  const { url } = match;
  const { pathname } = location;
  const { isAuthenticated } = useAccessToken();
  const { data } = useAuthFlow();
  const { step, method, recipient, challengeId, userId } = data;
  const defaultPath = isPlatform('hybrid') ? `${url}/phone` : `${url}/email`;

  /** Watches and handles routing based on data change */
  const handleRouting = useCallback(() => {
    let path = pathname;

    switch (step) {
      case AuthSteps.RegisterUser:
        path = `/auth/register`;
        if (recipient && method === ChallengeMethods.Phone) {
          path += `?phone=${recipient}`;
        }
        if (recipient && method === ChallengeMethods.Email) {
          path += `?email=${recipient}`;
        }
        break;
      case AuthSteps.ChallengeUser:
        if (method === ChallengeMethods.Phone) {
          path = `/auth/phone`;
          if (recipient) {
            path += `?phone=${recipient}`;
          }
        }
        if (method === ChallengeMethods.Email) {
          path = `/auth/email`;
          if (recipient) {
            path += `?email=${recipient}`;
          }
        }
        break;
      case AuthSteps.VerifyChallenge:
        if (challengeId && userId && recipient) {
          path = `/auth/verify`;
        }
        break;
      default:
        path = defaultPath;
    }

    if (pathname.includes('auth') && path && !path.includes(pathname)) {
      history.push(path);
      return;
    }
  }, [step, recipient, challengeId, userId, method, history, pathname, defaultPath]);

  useEffect(() => {
    handleRouting();
  }, [handleRouting]);

  if (isAuthenticated === true) {
    return <Redirect to={`/app/home`} />;
  }

  return (
    <IonRouterOutlet>
      <Route exact={true} path={`${url}/phone`} component={PhoneChallenge} />
      <Route exact={true} path={`${url}/email`} component={EmailChallenge} />
      <Route exact={true} path={`${url}/register`} component={RegisterUser} />
      <Route exact={true} path={`${url}/verify`} component={VerifyChallenge} />
      <Route path={url} render={() => <Redirect to={defaultPath} />} exact={true} />
    </IonRouterOutlet>
  );
};

export default Auth;
