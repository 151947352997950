import { IonButton, IonIcon, IonItem, IonTextarea } from '@ionic/react';
import { send } from 'ionicons/icons';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';

export interface CommentManagerProps {
  postComment: (content: string) => Promise<any>;
}

const useStyles = createUseStyles({
  message: {
    fontSize: 15,
    borderBottom: '0 !important',
    // Margins added to better vertically align text area with center of buttons
    '.md &': {
      marginTop: 7
    },
    '.ios &': {
      marginTop: 4
    }
  }
});

const CommentManager: React.FC<CommentManagerProps> = ({ postComment }) => {
  const classes = useStyles();
  const [content, setContent] = useState('');

  const onSubmit = async (e: any) => {
    e.preventDefault();
    await postComment(content);
    setContent('');
  };

  return (
    <form onSubmit={onSubmit}>
      <IonItem lines="none">
        <IonTextarea
          className={classes.message}
          color="primary"
          debounce={200}
          onIonChange={(e: any) => setContent(e.target.value)}
          placeholder="Message..."
          rows={1}
          value={content}
        />
        <IonButton disabled={!content.length} type="submit" fill="clear">
          <IonIcon icon={send} />
        </IonButton>
      </IonItem>
    </form>
  );
};

export default CommentManager;
