import { IonItem, IonLabel, IonList, IonText } from '@ionic/react';
import usePortal from 'hooks/usePortal';
import React from 'react';
import { createPortal } from 'react-dom';
import { createUseStyles } from 'react-jss';
import { animated, useTransition } from 'react-spring';

interface SearchResults {
  show: boolean;
  results: any[];
  onSelect: (arg0: any) => any;
  inputRef: any;
}

const useStyles = createUseStyles({
  results: {
    position: 'absolute',
    backgroundColor: 'white',
  },
  list: {
    padding: 0
  }
});

const SearchResults: React.FC<SearchResults> = ({ show, results, onSelect, inputRef }) => {
  const classes = useStyles();
  const target = usePortal('map-search-results');

  const resultsPos = {
    top: 100,
    bottom: 0,
    right: 0,
    left: 0
  };

  if (inputRef && inputRef.current) {
    const bounds: any = inputRef.current.getBoundingClientRect();
    const { top, bottom, left, right, height } = bounds;
    const { offsetLeft } = inputRef.current;
    resultsPos.top = top + height;
    resultsPos.bottom = window.outerHeight - bottom;
    resultsPos.left = left - offsetLeft;
    resultsPos.right = window.outerWidth - right - offsetLeft;
  }

  const listAnimationOptions = {
    from: {
      opacity: 0,
      ...resultsPos
    },
    enter: {
      opacity: 1,
      ...resultsPos
    },
    leave: {
      opacity: 0,
      ...resultsPos
    }
  };

  const transitionList = useTransition(show, null, listAnimationOptions);

  const itemsAnimationOptions = {
    enter: {
      opacity: 1,
      height: 60,
      transform: 'translate3d(0px,0px,0px)'
    },
    leave: {
      opacity: 0,
      height: 0,
      transform: 'translate3d(0px,20px,0px)'
    },
    from: {
      opacity: 0,
      height: 0,
      transform: 'translate3d(0px,-20px,0px)'
    },
    trail: 100,
    unique: true
  };

  let items: any[] = [];

  if (results.length > 0) {
    // Build address into seperate fields and add a key
    items = results.map((result, i) => {
      const parts = result.address.split(', ');

      return {
        ...result,
        key: i,
        title: parts[0],
        subtitle: `${parts[1]}, ${parts[2]}, ${parts[3]}`
      };
    });
  } else {
    items.push({
      key: 'no-results',
      title: 'No Results',
      subtitle: 'Try saying it a different way'
    });
  }

  const transitionItems = useTransition(items, item => item.key, itemsAnimationOptions);

  const list = transitionList.map(
    ({ item: i, key: k, props: p }) =>
      i && (
        <animated.div key={k} style={p} className={classes.results}>
          <IonList className={classes.list} lines="full">
            {transitionItems.map(({ item, props, key }) => (
              <animated.div key={key} style={props}>
                {key === 'no-results' ? (
                  <IonItem>
                    <IonLabel class="ion-text-wrap">
                      <IonText color="danger">
                        <h3>{item.title}</h3>
                      </IonText>
                      <IonText color="medium">
                        <h4>{item.subtitle}</h4>
                      </IonText>
                    </IonLabel>
                  </IonItem>
                ) : (
                  <IonItem onClick={() => onSelect(item)} button>
                    <IonLabel class="ion-text-wrap">
                      <IonText color="primary">
                        <h3>{item.title}</h3>
                      </IonText>
                      <IonText color="medium">
                        <h4>{item.subtitle}</h4>
                      </IonText>
                    </IonLabel>
                  </IonItem>
                )}
              </animated.div>
            ))}
          </IonList>
        </animated.div>
      )
  );

  return createPortal(list, target);
};

export default SearchResults;
