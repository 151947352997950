import axios from 'axios';
import { Location } from 'hooks/useLocation';
import mapboxgl from 'mapbox-gl';

export interface GeocodeOptions {
  country?: string;
  proximity?: [number, number];
  types?: string;
  limit?: number;
}

export class GeocoderService {
  private baseUrl: string;
  private accessToken: string;

  constructor(accessToken: string = mapboxgl.accessToken) {
    this.baseUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
    this.accessToken = accessToken;
  }

  searchByType = (type: string, features: any[] = []) => {
    const feature = features.filter((feat: any) => feat.place_type.includes(type));
    return features.length > 0 ? feature[0] : false;
  };

  reverse = (
    longitude: number,
    latitude: number,
    { country = 'US' }: GeocodeOptions = {}
  ): Promise<Location> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(`${this.baseUrl}/${longitude},${latitude}.json`, {
          params: {
            access_token: this.accessToken,
            country
          }
        });

        const { data } = response;
        const { features } = data;
        const feature = this.searchByType('address', features);

        const result = {
          address: undefined,
          latitude,
          longitude
        };

        if (feature) {
          result.address = feature.place_name;
        }

        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  };

  forward = (
    query: string,
    {
      limit = 5,
      proximity = [-95.417686, 30.079729],
      country = 'US',
      types = 'address'
    }: GeocodeOptions = {}
  ): Promise<Location[]> => {
    return new Promise(async (resolve, reject) => {
      try {
        if (query.length > 0) {
          const response = await axios.get(`${this.baseUrl}/${query}.json`, {
            params: {
              access_token: this.accessToken,
              country,
              proximity: `${proximity[0]},${proximity[1]}`,
              types,
              limit
            }
          });

          const { data } = response;
          const { features } = data;

          const mapped: Location[] = features.map((feature: any) => ({
            address: feature.place_name,
            latitude: feature.center[1],
            longitude: feature.center[0]
          }));

          resolve(mapped);
        } else {
          reject('No search term provided.');
        }
      } catch (err) {
        reject(err);
      }
    });
  };
}
