import { IonCard, IonCardContent, IonItem, IonLabel } from '@ionic/react';
import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

export interface ActivityCardProps {
  content: ReactNode | string;
  timestamp: number;
  graphic?: ReactNode;
  title: string;
  subtitle?: string;
  noSpace?: boolean;
}

const useStyles = createUseStyles({
  title: {
    fontWeight: 'bold !important',
    color: 'var(--ion-color-primary) !important'
  },
  subtitle: {
    color: 'var(--ion-color-dark) !important',
  },
  item: {
    borderBottom: '1px solid var(--ion-color-light)'
  }
});

const ActivityCard: React.FC<ActivityCardProps> = ({
  content,
  graphic,
  title,
  subtitle,
  noSpace = false
}) => {
  const classes = useStyles();

  return (
    <IonCard>
      <IonItem lines="none" className={classes.item}>
        {graphic}
        <IonLabel className="ion-text-wrap">
          {title && (<h4 className={classes.title}>{title}</h4>)}
          {subtitle && (<p className={classes.subtitle}> {subtitle} </p>)}
        </IonLabel>
      </IonItem>
      <IonCardContent className={noSpace ? 'ion-no-margin ion-no-padding' : ''}>
        {content}
      </IonCardContent>
    </IonCard>
  );
};

export default ActivityCard;
