import { IonContent, IonLoading, IonPage } from '@ionic/react';
import CodeInput from 'components/CodeInput';
import Form from 'components/Form';
import MessagesDisplay from 'containers/MessagesDisplay';
import useAuthFlow, { ChallengeMethods } from 'hooks/useAuthFlow';
import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

const VerifyChallenge: React.FC<RouteComponentProps> = ({ location: { search } }) => {
  const params = qs.parse(search, { ignoreQueryPrefix: true });
  const {
    verifyChallenge,
    formatRecipient,
    data,
    loading,
    startChallenge,
    switchMethod
  } = useAuthFlow(params);
  const { userId, challengeId, recipient, method } = data;
  const [code, setCode] = useState('');
  const [validated, setValidated] = useState<boolean>(false);
  const checkValidation = useCallback(
    () => setValidated(code && code.length === 6 ? true : false),
    [code, setValidated]
  );
  const handleNext = useCallback(() => {
    if (validated && userId && challengeId) {
      setCode('');
      verifyChallenge({ userId, challengeId, answer: code });
    }
  }, [userId, challengeId, code, validated, verifyChallenge, setCode]);

  /** Responsible for checking the validity of code input */
  useEffect(() => {
    checkValidation();
  }, [checkValidation]);

  const subtitle = (
    <span>
      A verification code was sent to <strong>{recipient && formatRecipient(recipient)}</strong>.
    </span>
  );

  const buttons = [
    {
      text: 'Next',
      handler: handleNext,
      isDisabled: !validated
    },
    {
      text: 'No code?',
      color: 'tertiary',
      primary: false,
      handler: () => startChallenge({ method, recipient, reissue: true })
    },
    {
      text: 'Use a different Phone or Email',
      color: 'tertiary',
      primary: false,
      handler: () => switchMethod(ChallengeMethods.Phone)
    }
  ];

  return (
    <IonPage>
      <IonContent color="background">
        <IonLoading isOpen={loading} duration={10000} spinner="crescent" message="One Moment..." />
        <MessagesDisplay />
        <Form title="Verification Code OTP" subtitle={subtitle} buttons={buttons}>
          <CodeInput value={code} onChange={setCode} autofocus />
        </Form>
      </IonContent>
    </IonPage>
  );
};

export default VerifyChallenge;
