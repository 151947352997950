import gql from 'graphql-tag';

export const resolvers = {
  Mutation: {
    updateMessage: (_: any, params: any, { cache }: any) => {
      const query = gql`
        query GetMessage {
          message @client {
            color
            code
            content
          }
        }
      `;

      const { message } = cache.readQuery({ query });
      const { input } = params;
      Object.keys(input).forEach(key => input[key] === undefined && delete input[key]);
      const data = {
        message: {
          ...message,
          ...input
        }
      };

      cache.writeQuery({ query, data });
      return data.message;
    },
    updateAuthFlow: async (_: any, params: any, { cache }: any) => {
      const query = gql`
        query AuthFlow {
          authFlow @client {
            step
            method
            userId
            challengeId
            recipient
            reissue
          }
        }
      `;

      const { input } = params;
      const { authFlow } = cache.readQuery({ query });
      Object.keys(input).forEach(key => input[key] === undefined && delete input[key]);
      const data = {
        authFlow: {
          ...authFlow,
          ...input
        }
      };

      cache.writeQuery({ query, data });

      return data.authFlow;
    }
  }
};
