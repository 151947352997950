import { Plugins } from '@capacitor/core';
import { IonButton, IonCard, IonCardContent } from '@ionic/react';
import SectionTitle from 'components/SectionTitle';
import React from 'react';
const { Browser } = Plugins;

const FeedbackCard: React.FC = () => {
  const openFeedbackForm = async () => {
    await Browser.open({ url: 'https://forms.gle/rWJSQQ995jftoszv5' });
  };

  return (
    <IonCard>
      <SectionTitle title="Feedback" />
      <IonCardContent>
        <p className="ion-text-wrap">
          We value your feedback and would love to know your thoughts so we can improve this app.
        </p>
        <p className="ion-padding-top ion-text-wrap">
          If you need assistance, you may also contact us at{' '}
          <a href="mailto:p3worksapp@mctx.org">
            <strong>p3worksapp@mctx.org</strong>
          </a>
          .
        </p>
        <div className="ion-padding-top">
          <IonButton fill="outline" color="primary" expand="block" onClick={openFeedbackForm}>
            Submit Feedback
          </IonButton>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default FeedbackCard;
