import { IonButton, IonImg, IonModal, IonSlide, IonSlides } from '@ionic/react';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';

export type IPhotoImage = {
  id: string;
  src: string;
};

const useStyles = createUseStyles({
  content: {
    position: 'relative'
  },
  image: {
    objectFit: 'cover',
    height: '250px !important',
    width: '100% !important'
  },
  inspector: {
    height: '100%'
  }
});

const PhotoImage = ({ id, src }: IPhotoImage) => {
  const classes = useStyles();
  const [showInspector, setShowInspector] = useState(false);

  const sliderOptions = {
    zoom: {
      maxRatio: 5
    },
    allowSlidePrev: false,
    allowSlideNext: false
  };

  return (
    <React.Fragment>
      <div className={classes.content}>
        <IonImg
          src={src}
          alt={`Photo Upload ${id}`}
          className={classes.image}
          onClick={() => setShowInspector(true)}
        />
      </div>

      <IonModal backdropDismiss={false} isOpen={showInspector}>
        <IonSlides className={classes.inspector} options={sliderOptions}>
          <IonSlide>
            <div key={id} className="swiper-zoom-container">
              <IonImg src={src} alt={`Photo Upload ${id}`} />
            </div>
          </IonSlide>
        </IonSlides>
        <IonButton
          onClick={() => setShowInspector(false)}
          expand="full"
          color="secondary"
          className="ion-no-margin"
        >
          Done
        </IonButton>
      </IonModal>
    </React.Fragment>
  );
};

export default PhotoImage;
