import { isPlatform } from '@ionic/react';
import drainageIcon from 'assets/icons/drainage.svg';
import roadsIcon from 'assets/icons/roads.svg';
import signalsIcon from 'assets/icons/signals.svg';
import signsIcon from 'assets/icons/signs.svg';
import { build } from 'ionicons/icons';

export const state: any = {
  authFlow: {
    __typename: 'AuthFlow',
    step: 'CHALLENGE_USER',
    method: isPlatform('hybrid') ? 'Phone' : 'Email',
    userId: null,
    challengeId: null,
    recipient: null,
    reissue: false
  },
  message: {
    __typename: 'Message',
    code: null,
    color: null,
    content: null
  },
  categories: [
    {
      __typename: 'Category',
      color: 'primary',
      name: 'Drainage',
      description: 'Select this for sewer, water, etc.',
      icon: drainageIcon
    },
    {
      __typename: 'Category',
      color: 'primary',
      name: 'Roads',
      description: 'Select this for sidewalks, cracks, etc.',
      icon: roadsIcon
    },
    {
      __typename: 'Category',
      color: 'primary',
      name: 'Signs',
      description: 'Select this for stop signs, etc.',
      icon: signsIcon
    },
    {
      __typename: 'Category',
      color: 'primary',
      name: 'Signals',
      description: 'Select this for traffic lights, etc.',
      icon: signalsIcon
    },
    {
      __typename: 'Category',
      color: 'primary',
      name: 'Other',
      description: 'Select this for anything else.',
      icon: build
    }
  ]
};
