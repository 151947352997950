import { IonRouterOutlet } from '@ionic/react';
import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';


import RequestDetails from './RequestDetails';
import YourRequests from './YourRequests';

const Requests: React.FC<RouteComponentProps> = ({ match }) => {
  const { url } = match;

  return (
    <IonRouterOutlet>
      <Route exact={true} path={`${url}/`} component={YourRequests} />
      <Route exact={true} path={`${url}/details/:id`} component={RequestDetails} />
    </IonRouterOutlet>
  );
};

export default Requests;
