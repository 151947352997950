import { IonSearchbar, IonToolbar } from '@ionic/react';
import SearchResults from 'components/SearchResults';
import { Location, useLocation } from 'hooks/useLocation';
import React, { useEffect, useRef, useState } from 'react';

interface IMapSearchToolbar {
  onSelectedLocation: (arg0: Location) => any;
}

const MapSearchToolbar: React.FC<IMapSearchToolbar> = ({ onSelectedLocation }) => {
  const [query, setQuery] = useState('');
  const [display, setDisplay] = useState(false);
  const [results, setResults] = useState<Location[]>([]);
  const searchEl: any = useRef(null);

  const { forwardGeocode } = useLocation();

  const onChange = async (_: CustomEvent) => {
    if (query && query.length > 0) {
      const items = await forwardGeocode(query);
      setResults(items);
    }
  };

  const onInput = (e: CustomEvent) => {
    const { value }: any = e.target;
    setQuery(value);
  };

  const onSelectResult = (result: Location) => {
    setQuery('');
    if (onSelectedLocation) onSelectedLocation(result);
  };

  // Handles displaying the results
  useEffect(() => {
    if (query && query.length > 0) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }, [query]);

  return (
    <IonToolbar>
      <IonSearchbar
        placeholder="Find an address"
        inputmode="search"
        debounce={500}
        onIonInput={onInput}
        onIonChange={onChange}
        value={query}
        ref={el => (searchEl.current = el)}
      />

      <SearchResults
        show={display}
        results={results}
        onSelect={onSelectResult}
        inputRef={searchEl}
      />
    </IonToolbar>
  );
};

export default MapSearchToolbar;
