import { IonCard, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react';
import SectionTitle from 'components/SectionTitle';
import { RequestCategory, useCategories } from 'hooks/useCategories';
import React from 'react';
import { useHistory } from 'react-router-dom';

const SelectCategoryCard: React.FC = () => {
  const history = useHistory();
  const { categories } = useCategories();

  const update = (category: RequestCategory) => {
    history.push(`/app/new-request?category=${category}`);
  };

  if (categories.length > 0) {
    return (
      <IonCard color="white">
        <SectionTitle title="Report an issue" color="white" />

        <IonList lines="full">
          {categories.map(({ name, icon, color }: any, i: number) => (
            <IonItem key={i} onClick={() => update(name)} detail>
              <IonIcon icon={icon} slot="start" color={color} style={{ fontSize: '1.25em' }}/>
              <IonLabel>{name}</IonLabel>
            </IonItem>
          ))}
        </IonList>
      </IonCard>
    );
  }

  return null;
};

export default SelectCategoryCard;
