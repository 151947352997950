import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import useMessage from 'hooks/useMessage';
import { useEffect, useState } from 'react';

export interface Photo {
  __typeName?: string;
  id: string;
  path: string;
  src: string;
  uploadedAt: number;
  uploadedBy: string;
}

export const REQUEST_PHOTOS = gql`
  query requestPhotos($requestId: ID!) {
    request(id: $requestId) {
      id
      photos {
        id
        src
        uploadedAt
        uploadedBy
      }
    }
  }
`;

export const UPLOAD_PHOTO = gql`
  mutation uploadPhoto($file: Upload!, $requestId: ID!) {
    uploadRequestPhoto(input: { file: $file, requestId: $requestId }) {
      request {
        id
        photos {
          id
          src
          uploadedAt
          uploadedBy
        }
      }
      photoId
    }
  }
`;

export const DELETE_PHOTO = gql`
  mutation removeRequestPhoto($photoId: ID!, $requestId: ID!) {
    removeRequestPhoto(input: { photoId: $photoId, requestId: $requestId }) {
      request {
        id
        photos {
          id
          src
        }
      }
    }
  }
`;

export interface PhotoGalleryConfig {
  requestId: string;
}

/** Hook used to manage a gallery, take photos and save them to the service */
export function usePhotoGallery({ requestId }: PhotoGalleryConfig) {
  const [loading, setLoading] = useState(false);
  const { message, setMessage } = useMessage();
  const { data, refetch } = useQuery(REQUEST_PHOTOS, {
    variables: { requestId }
  });

  useEffect(() => {
    refetch();
  }, [requestId, refetch]);

  const photos: Photo[] = data && data.request ? data.request.photos : [];

  const [uploadPhoto] = useMutation(UPLOAD_PHOTO);
  const [deletePhoto] = useMutation(DELETE_PHOTO);

  const handleError = (e: any) => {
    setMessage({
      content: e.message || 'We are unable to do this right now.',
      color: 'danger'
    });
    setLoading(false);
  };

  /* Removes a photo */
  const removePhoto = async (photoId: string) => {
    try {
      setLoading(true);
      await deletePhoto({ variables: { requestId, photoId } });
      setLoading(false);
    } catch (err) {
      handleError(err);
    }
  };

  const onInputSelect = async () => {
    try {
      const filePicker = document.querySelector('input');
      if (!filePicker || !filePicker.files || filePicker.files.length <= 0) {
        throw new Error('No file selected.');
      }

      setLoading(true);

      const promises = [];
      for (const file of Array.from(filePicker.files)) {
        promises.push(uploadPhoto({ variables: { requestId, file } }));
      }

      await Promise.all(promises);

      setLoading(false);
    } catch (err) {
      handleError(err);
    }
  };

  const selectPhoto = async () => {
    const filePicker = document.querySelector('input');
    if (!filePicker) throw new Error('Issue selecting a photo');
    filePicker.click();
  };

  return {
    message,
    loading,
    refetch,
    photos,
    selectPhoto,
    onInputSelect,
    removePhoto
  };
}
