import { IonAvatar, IonCard, IonItem, IonLabel, IonSkeletonText, IonThumbnail } from '@ionic/react';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  card: {
    '& ion-item:first-child': {
      '--inner-padding-end': 0,
      '& ion-thumbnail': {
        margin: 0,
        width: '100px',
        height: '100%'
      },
      '& ion-label.md': {
        marginRight: 10
      }
    },
    '& ion-item:last-child': {
      borderTop: '1px solid var(--ion-color-light)',
      '& ion-icon': {
        'margin-inline-end': '5px'
      }
    }
  },
  icon: {
    'margin-inline-end': '5px !important',
    width: 34,
    height: 34
  }
});

const RequestItemSkeleton = () => {
  const classes = useStyles();

  return (
    <IonCard color="white" className={classes.card}>
      <IonItem lines="none">
        <IonLabel className="ion-text-wrap">
          <h3>
            <IonSkeletonText animated={true} style={{ width: '100%', height: '1.25em' }} />
          </h3>
          <p>
            <IonSkeletonText animated={true} style={{ width: '100%', height: '0.875em' }} />
          </p>
          <p>
            <IonSkeletonText animated={true} style={{ width: '100%', height: '0.825em' }} />
            <IonSkeletonText animated={true} style={{ width: '100%', height: '0.825em', margin: 0 }} />
          </p>
        </IonLabel>

        <IonThumbnail slot="end">
          <IonSkeletonText animated={true} style={{ height: '100px' }} />
        </IonThumbnail>
      </IonItem>
      <IonItem lines="none">
        <IonAvatar slot="start" className={classes.icon}>
          <IonSkeletonText animated />
        </IonAvatar>
        <IonLabel color="medium">
          <p><IonSkeletonText animated={true} style={{ width: '80%', height: '0.875em' }} /> </p>
        </IonLabel>
      </IonItem>
    </IonCard>
  );
};

export default RequestItemSkeleton;
