// tslint:disable ordered-imports
// Core CSS required for Ionic components to work properly
import '@ionic/react/css/core.css';
// Basic CSS for apps built with Ionic
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
// Optional CSS utils
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import 'assets/theme.css';

import { useApolloClient } from '@apollo/react-hooks';
import { Plugins, StatusBarStyle } from '@capacitor/core';
import { isPlatform } from '@ionic/core';
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { build, cog, home } from 'ionicons/icons';
import HomePage from 'pages/Home';
import NewRequestPage from 'pages/NewRequest';
import RequestsPage from 'pages/Requests';
import SettingsPage from 'pages/Settings';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { initPush } from 'services/push';

const { StatusBar } = Plugins;

const darkStatusBarRoutes = ['/app/home'];

const useStyles = createUseStyles({
  button: {
    '--color-selected': 'var(--ion-color-secondary)',
    '--color': 'var(--ion-color-medium)',
    '&.tab-selected': {
      borderTop: '3px solid var(--ion-color-secondary)'
    }
  }
});

const App: React.FC<RouteComponentProps> = ({ match: { url }, location: { pathname } }) => {
  const classes = useStyles();

  const apolloClient = useApolloClient();

  useEffect(() => {
    initPush(apolloClient);
  }, [apolloClient]);

  useEffect(() => {
    if (!isPlatform('capacitor')) return;
    if (darkStatusBarRoutes.includes(pathname)) {
      StatusBar.setStyle({ style: StatusBarStyle.Dark });
    } else {
      StatusBar.setStyle({ style: StatusBarStyle.Light });
    }
  }, [pathname]);

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route path={`${url}/new-request`} component={NewRequestPage} />
        <Route path={`${url}/home`} component={HomePage} />
        <Route path={`${url}/requests`} component={RequestsPage} />
        <Route path={`${url}/settings`} component={SettingsPage} />
        <Route path={`${url}`} render={() => <Redirect to={`${url}/home`} />} exact={true} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="home" href={`${url}/home`} className={classes.button}>
          <IonIcon icon={home} />
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton tab="requests" href={`${url}/requests`} className={classes.button}>
          <IonIcon icon={build} />
          <IonLabel>Requests</IonLabel>
        </IonTabButton>
        <IonTabButton tab="settings" href={`${url}/settings`} className={classes.button}>
          <IonIcon icon={cog} />
          <IonLabel>Settings</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default App;
