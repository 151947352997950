import { useQuery } from '@apollo/react-hooks';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonProgressBar,
  IonRow,
  IonSlide,
  IonSlides
} from '@ionic/react';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { onPushNotificationReceived, removedDeliveredNotifications } from 'services/push';

export interface Alert {
  id: string;
  message: string;
}

const GET_ALERTS = gql`
  query GetAlerts {
    alerts {
      id
      message
    }
  }
`;

const SingleAlert: React.FC<{ alert: Alert; onDismiss: (alertId: Alert['id']) => void }> = ({
  alert,
  onDismiss
}) => (
  <IonGrid className="ion-padding">
    <IonRow className="ion-justify-content-center">
      <IonCol
        size="12"
        sizeMd="6"
        sizeLg="4"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between'
        }}
      >
        <IonLabel color="inherit">
          <p className="ion-text-left" style={{ lineHeight: 20 / 14, whiteSpace: 'pre-wrap' }}>
            {alert.message}
          </p>
        </IonLabel>
        <IonButton
          mode="md"
          size="small"
          shape="round"
          fill="clear"
          style={{
            marginTop: '-4px',
            marginRight: 'calc(var(--ion-margin, 16px) * -1)',
            opacity: 0.64
          }}
          onClick={() => onDismiss(alert.id)}
        >
          <IonIcon slot="icon-only" icon="close" size="small" color="inherit" />
        </IonButton>
      </IonCol>
    </IonRow>
  </IonGrid>
);

const slideAutoplayDelay = 15000;

const useStyles = createUseStyles({
  container: {
    paddingTop: 'var(--ion-safe-area-top, 0)',
    background: 'var(--ion-color-secondary)',
    boxShadow: '0 4px 16px rgb(0 0 0 / 12%)',
    '--ion-color-base': 'var(--ion-color-secondary-contrast)'
  },
  containerEmpty: {
    paddingTop: 'var(--ion-safe-area-top, 0)',
    background: 'rgb(22, 34, 58)'
  },
  progressBar: {
    height: 6,
    '--background': 'var(--ion-color-secondary)',
    '--progress-background': 'rgba(0, 0, 0, 0.12)'
  }
});

const AlertBanner: React.FC = () => {
  const classes = useStyles();

  const [slideProgress, setSlideProgress] = useState<number>(0);
  // Could persist to local storage if we want to permanently dismiss on device
  const [dismissedAlerts, setDismissedAlerts] = useState<Alert['id'][]>([]);

  const { pathname } = useLocation();
  const { url } = useRouteMatch();
  const isPageActive = pathname === url;

  const { data, refetch } = useQuery<{ alerts: Alert[] }>(GET_ALERTS, {
    fetchPolicy: 'network-only',
    pollInterval: isPageActive ? 60 * 1000 : 0
  });
  const alerts = (data?.alerts || []).filter(alert => !dismissedAlerts.includes(alert.id));
  const multipleAlerts = alerts.length > 1;

  const dismissAlert = (id: Alert['id']) => setDismissedAlerts(prev => [...prev, id]);

  const sliderOptions = {
    // Dismiss button handlers are not attached after loop if `true`.
    // https://github.com/ionic-team/ionic-framework/issues/22414
    loop: false,
    spaceBetween: 24,
    autoplay: isPageActive ? { delay: slideAutoplayDelay, disableOnInteraction: false } : undefined
  };

  /**
   * `key` for `IonSlides` from alert ids.
   * Changing the key as the alerts change will remount slides
   * and prevent `Failed to execute 'removeChild'` exception removing dynamic slides.
   * https://github.com/ionic-team/ionic-framework/issues/18782#issuecomment-558075082
   * Slides will also remount when leaving and returning to page. Alerts will start back at beginning.
   */
  const slidesKey = `${isPageActive ? 1 : 0}_${alerts.map(({ id }) => id).join('_')}`;

  useEffect(() => {
    if (!(isPageActive && multipleAlerts)) {
      setSlideProgress(0);
      return undefined;
    }

    const interval = 3000;

    const intervalId = setInterval(() => {
      setSlideProgress(current => current + interval / slideAutoplayDelay);
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [isPageActive, multipleAlerts]);

  useEffect(() => {
    // Refetch alerts if a push notification is received.
    // Alerts are the only push notifications for P3Works.
    onPushNotificationReceived(() => refetch());
  }, [refetch]);

  useEffect(() => {
    // Remove all from the notifications screen (and badge)
    // since they are now visible in the banner
    removedDeliveredNotifications();
  }, []);

  // Assume `AlertBanner` is always at the top of the home page.
  // If no alerts, render with height of iOS unsafe area
  if (alerts.length === 0) return <div className={classes.containerEmpty} />;

  return (
    <React.Fragment>
      <div className={classes.container}>
        {multipleAlerts ? (
          <IonSlides
            key={slidesKey}
            options={sliderOptions}
            onIonSlideDidChange={() => setSlideProgress(0)}
          >
            {alerts.map(alert => (
              <IonSlide key={alert.id}>
                <SingleAlert alert={alert} onDismiss={dismissAlert} />
              </IonSlide>
            ))}
          </IonSlides>
        ) : (
          <SingleAlert alert={alerts[0]} onDismiss={dismissAlert} />
        )}
        <IonProgressBar className={classes.progressBar} value={slideProgress} reversed={true} />
      </div>
    </React.Fragment>
  );
};

export default AlertBanner;
