import { IonIcon, IonText } from '@ionic/react';
import { IStatus, RequestStatus, useStatus } from 'hooks/useStatus';
import React from 'react';
import { createUseStyles } from 'react-jss';

type IStat = {
  status: RequestStatus;
  count: number;
};

const useStyles = createUseStyles({
  stat: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
    margin: -1,
    padding: 15,
    border: '1px solid var(--ion-color-light)',
    borderBottom: 'none',
    '&:nth-child(odd)': {
      borderRight: 'none'
    }
  },
  iconArea: {
    display: 'flex'
  },
  textArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  value: {
    fontWeight: 'bold',
    fontSize: '1.25em'
  }
});

const Stat = ({ status, count }: IStat) => {
  const classes = useStyles();
  const { color, label, icon }: IStatus = useStatus(status);

  return (
    <div className={classes.stat}>
      <div className={classes.iconArea}>
        <IonIcon icon={icon} color={color} />
      </div>
      <div className={classes.textArea}>
        <IonText color="primary" className={classes.value}>{count}</IonText>
        <IonText color="primary">{label}</IonText>
      </div>
    </div>
  );
};

export default Stat;
