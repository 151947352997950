import { IonItem, IonLabel } from '@ionic/react';
import React from 'react';
import { createUseStyles } from 'react-jss';

type ISectionHeader = {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  color?: string;
};

const useStyles = createUseStyles({
  title: {
    fontSize: '1.25em !important',
    fontWeight: 'bold !important',
    color: 'var(--ion-color-primary) !important'
  },
  subtitle: {
    fontSize: '0.875em !important',
    color: 'var(--ion-color-dark) !important',
    padding: [10, 0]
  }
});

const SectionTitle = ({
  title,
  subtitle,
  color = 'white'
}: ISectionHeader) => {
  const classes = useStyles();

  return (
    <IonItem color={color} lines="none">
      <IonLabel className={`ion-text-wrap`}>
        <h2 className={classes.title}>{title}</h2>
        {subtitle && (
          <p className={classes.subtitle}>{subtitle}</p>
        )}
      </IonLabel>
    </IonItem>
  );
};

export default SectionTitle;
