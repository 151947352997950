import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export enum RequestCategory {
  Drainage = 'Drainage',
  Roads = 'Roads',
  Signals = 'Signals',
  Signs = 'Signs',
  Other = 'Other'
}

export const GET_CATEGORIES = gql`
  query GetCategories {
    categories @client {
      name
      color
      description
      icon
    }
  }
`;

export const useCategories = () => {
  const { data, error, loading } = useQuery(GET_CATEGORIES);
  const { categories } = data;

  return {
    categories,
    error,
    loading
  };
};
