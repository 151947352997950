import { JSX } from '@ionic/core';
import { IonInput, IonItem, IonLabel } from '@ionic/react';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Rifm } from 'rifm';

interface CodeInputProps {
  label?: string;
  value: string;
  onChange: (arg0: any) => void;
}

const useStyles = createUseStyles({
  input: {
    textAlign: 'center',
    letterSpacing: '0.3em',
    '& .native-input': {
      '--padding-start': '10px'
    }
  }
});

const CodeInput: React.FC<CodeInputProps & JSX.IonInput> = ({
  label,
  onChange,
  value,
  ...inputProps
}) => {
  const classes = useStyles();

  return (
    <Rifm
      accept={/[\d.$]/g}
      mask={
        value.length < 6
          ? undefined
          : value.length === 6
      }
      format={v => v}
      value={value}
      onChange={onChange}
    >
      {({ onChange: x, value: y }: any) => (
        <IonItem lines="none">
          {label && <IonLabel position="stacked" color="dark">{label}</IonLabel>}
          <IonInput
            {...inputProps}
            inputmode="numeric"
            type="tel"
            value={y}
            onIonChange={x}
            placeholder="******"
            className={classes.input}
          />
        </IonItem>
      )}
    </Rifm>
  );
};

export default CodeInput;
