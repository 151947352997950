import { IonContent, IonLoading, IonPage } from '@ionic/react';
import EmailInput from 'components/EmailInput';
import Form from 'components/Form';
import PhoneInput from 'components/PhoneInput';
import TextInput from 'components/TextInput';
import MessagesDisplay from 'containers/MessagesDisplay';
import useAuthFlow from 'hooks/useAuthFlow';
import qs from 'qs';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

const RegisterUser: React.FC<RouteComponentProps> = ({
  location: { search }
}) => {
  const params = qs.parse(search, { ignoreQueryPrefix: true });
  const { registerUser, endChallenge, loading } = useAuthFlow();
  const [firstName, setFirstname] = useState<string>(params.firstName || '');
  const [lastName, setLastName] = useState<string>(params.lastName || '');
  const [phone, setPhone] = useState<string>(params.phone || '');
  const [email, setEmail] = useState<string>(params.email || '');

  const submit = () => {
    registerUser({ firstName, lastName, phone, email });
  };

  const subtitle = <span>Please provide us the following information to create an account.</span>;

  const buttons = [{
    text: 'Register',
    handler: submit
  }, {
    text: 'Already have an account?',
    color: 'tertiary',
    primary: false,
    handler: () => endChallenge()
  }];

  return (
    <IonPage>
      <IonContent color="background">
        <IonLoading isOpen={loading} duration={10000} spinner="crescent" message="One Moment..."/>
        <MessagesDisplay />
        <Form title="Registration" subtitle={subtitle} buttons={buttons}>
          <TextInput value={firstName} onChange={setFirstname} label="First Name" autofocus />
          <TextInput value={lastName} onChange={setLastName} label="Last Name" />
          <EmailInput value={email} onChange={setEmail} label="Email Address" />
          <PhoneInput value={phone} onChange={setPhone} label="Phone Number" />
        </Form>
      </IonContent>
    </IonPage>
  );
};

export default RegisterUser;
