import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useState } from 'react';
import validator from 'validator';
import useAccessToken from './useAccessToken';
import useMessage from './useMessage';

export const CREATE_USER = gql`
  mutation CreateUser(
    $firstName: String!,
    $lastName: String!,
    $phone: String!,
    $email: String!
  ) {
    createUser(
      input: {
        firstName: $firstName,
        lastName: $lastName,
        phone: $phone,
        email: $email
      }
    ) {
      user {
        id
        firstName
        lastName
        phone
        email
      }
    }
  }
`;

export const CHALLENGE_USER = gql`
  mutation ChallengeUser($method: ChallengeMethods!, $phone: String, $email: String, $reissue: Boolean) {
    challengeUser(input: { method: $method, phone: $phone, email: $email,  reissue: $reissue }) {
      userId
      challengeId
      resent
      reissued
    }
  }
`;

export const VERIFY_USER = gql`
  mutation VerifyUser($userId: ID!, $challengeId: ID!, $answer: String!) {
    verifyUser(input: { userId: $userId, challengeId: $challengeId, answer: $answer }) {
      token
    }
  }
`;

export const GET_AUTH_FLOW = gql`
  query AuthFlow {
    authFlow @client {
      step
      method
      userId
      challengeId
      recipient
      reissue
    }
  }
`;

export const UPDATE_AUTH_FLOW = gql`
  mutation updateAuthFlow(
    $step: AuthSteps,
    $method: ChallengeMethods,
    $userId: String,
    $challengeId: String,
    $reissue: Boolean,
    $recipient: String
  ) {
    updateAuthFlow(
      input: {
        step: $step,
        method: $method,
        userId: $userId,
        challengeId: $challengeId,
        reissue: $reissue,
        recipient: $recipient
      }
    ) @client
  }
`;

// The steps for routing
export enum AuthSteps {
  RegisterUser = 'REGISTER_USER',
  ChallengeUser = 'CHALLENGE_USER',
  VerifyChallenge = 'VERIFY_CHALLENGE'
}

// The available challenges
export enum ChallengeMethods {
  Phone = 'Phone',
  Email = 'Email'
}

interface RegistrationFields {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

// The input for startChallenge function
interface StartChallengeFields {
  method: ChallengeMethods;
  recipient: string;
  reissue?: boolean;
}

// The input for verifyChallenge function
interface VerifyChallengeFields {
  userId: string;
  challengeId: string;
  answer: string;
}

// The state of the challenge in general
interface AuthFlowData {
  step?: AuthSteps;
  method: ChallengeMethods;
  userId?: string;
  challengeId?: string;
  recipient: string;
  reissue?: boolean;
}

interface AuthFlowMethods {
  logoutUser: () => void;
  switchMethod: (arg0: ChallengeMethods) => void;
  registerUser: (arg0: RegistrationFields) => void;
  startChallenge: (arg0: StartChallengeFields) => void;
  verifyChallenge: (arg0: VerifyChallengeFields) => void;
  endChallenge: () => void;
  formatRecipient: (arg0: string) => string;
  isValidInput: (arg0: ChallengeMethods, arg1: string) => boolean;
  data: AuthFlowData;
  loading: boolean;
}

const useAuthFlow = (defaultData?: AuthFlowData): AuthFlowMethods => {
  const [loading, setLoading] = useState<boolean>(false);
  const { setMessage } = useMessage();
  const { saveAccessToken } = useAccessToken();
  // Local state mutation and query
  const client = useApolloClient();
  const [updateAuthFlow] = useMutation(UPDATE_AUTH_FLOW);
  const { data: d } = useQuery(GET_AUTH_FLOW);
  const data: AuthFlowData = (d && d.authFlow) || defaultData || {};

  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: async(r: any) => {
      if (r && r.createUser && r.createUser.user){
        const { id, phone } = r.createUser.user;
        await updateAuthFlow({
          variables: {
            step: AuthSteps.ChallengeUser,
            userId: id,
            recipient: phone
          }
        });

        // Auto init a challenge
        await startChallenge({ method: data.method, recipient: data.recipient });
        return;
      }
    },
    onError: async(e: any) => {
      if (e && e.graphQLErrors) {
        const err = (e.graphQLErrors && e.graphQLErrors[0]) ? e.graphQLErrors[0] : {};
        if (err.code && err.message){
          await setMessage({
            code: err.code,
            content: err.message
          });
          return;
        }else{
          await setMessage({
            code: 'GenericError',
            color: 'danger',
            content: typeof err === 'string' ? err : 'We are having issues right now, please contact us.'
          });
          return;
        }
      }
    }
  });

  const [challengeUser] = useMutation(CHALLENGE_USER, {
    onCompleted: async (r: any) => {
      if (r && r.challengeUser) {
        if (r.challengeUser.reissued){
          await updateAuthFlow({
            variables: {
              step: AuthSteps.VerifyChallenge,
              challengeId: r.challengeUser.challengeId,
              userId: r.challengeUser.userId,
              reissue: false,
            }
          });

          await setMessage({
            code: 'AutoChanged',
            color: 'success',
            content: 'We have sent you a new code.'
          });
          return;
        }

        await updateAuthFlow({
          variables: {
            step: AuthSteps.VerifyChallenge,
            challengeId: r.challengeUser.challengeId,
            userId: r.challengeUser.userId,
            reissue: false,
          }
        });

        return;
      }
    },
    onError: async (e: any) => {
      if (e && e.graphQLErrors) {
        const err = (e.graphQLErrors && e.graphQLErrors[0]) ? e.graphQLErrors[0] : {};

        if (err.code && err.message){

          if (err.code === 'NotFound'){
            await updateAuthFlow({
              variables: {
                step: AuthSteps.RegisterUser
              }
            });
            return;
          }

          if (err.code === 'DuplicateRequest') {
            await updateAuthFlow({
              variables: {
                step: AuthSteps.ChallengeUser,
                reissue: true
              }
            });

            await setMessage({
              code: err.code,
              color: 'warning',
              content: 'We already sent a code, if you need another one, you may try again.'
            });
            return;
          }

          // Most errors from server
          await setMessage({
            code: err.code,
            color: err.color || 'danger',
            content: err.message
          });
          return;
        }else{
          await setMessage({
            code: 'GenericError',
            color: 'danger',
            content: typeof err === 'string' ? err : 'We are having issues right now, please contact us.'
          });
          return;
        }
      }
    }
  });
  const [verifyUser] = useMutation(VERIFY_USER, {
    onCompleted: async (r: any) => {
      if (r && r.verifyUser) {
        await endChallenge();
        await saveAccessToken(r.verifyUser.token);
        return;
      }
    },
    onError: async (e: any) => {
      if (e && e.graphQLErrors) {
        const err = (e.graphQLErrors && e.graphQLErrors[0]) ? e.graphQLErrors[0] : {};

        if (err.code && err.message){
          await setMessage({
            code: err.code,
            color: err.color || 'danger',
            content: err.message
          });

          if (err.code === 'BadRequest' || err.code === 'NotFound') {
            if (data && data.recipient){
              // Auto init a new challenge
              await startChallenge({
                method: data.method,
                recipient: data.recipient,
                reissue: true
              });
              return;
            }
          }
          return;
        }else{
          await setMessage({
            code: 'GenericError',
            color: 'danger',
            content: typeof err === 'string' ? err : 'We are having issues right now, please contact us.'
          });
          return;
        }
      }
    }
  });

  /** Pure function for turning recipient string into standard readable format */
  const formatRecipient = (givenValue: string): string => {
    const value = decodeURI(givenValue);
    const phoneNumber = parsePhoneNumberFromString(value, 'US');

    if (phoneNumber) {
      return String(phoneNumber.format('NATIONAL'));
    }

    if (validator.isEmail(value)) {
      return value;
    }

    return value;
  };

  /** Pure function for turning phone string into E.164 standard +1 format */
  const formatPhone = (value: string): string => {
    const phoneNumber = parsePhoneNumberFromString(value, 'US');
    if (!phoneNumber) return value;
    return String(phoneNumber.format('E.164'));
  };

  /** Pure function that checks the validity of method data  */
  const isValidInput = (method: ChallengeMethods, value: string): boolean => {
    if (method === ChallengeMethods.Phone && value) {
      const mobileNumber = formatPhone(value);
      const isValidPhone = validator.isMobilePhone(mobileNumber, 'en-US', { strictMode: true });
      if (isValidPhone) return true;
      return false;
    }

    if (method === ChallengeMethods.Email && value) {
      const isValidEmail = validator.isEmail(value);
      if (isValidEmail) return true;
      return false;
    }

    return false;
  };

  const registerUser = async ({firstName, lastName, phone, email}: RegistrationFields) => {
      // validate the input fields
      if (firstName.length === 0){
        await setMessage({ content: 'You did not provide a first name.'});
        return;
      }

      if (lastName.length === 0){
        await setMessage({ content: 'You did not provide a last name.'});
        return;
      }

      if (!validator.isEmail(email)){
        await setMessage({ content: 'You did not provide a proper email.' });
        return;
      }

      const phoneNumber = parsePhoneNumberFromString(phone, 'US') || { number: phone };
      const mobileNumber = phoneNumber ? phoneNumber.number : phone;

      if (!validator.isMobilePhone(String(mobileNumber), 'en-US', { strictMode: true })){
        await setMessage({ content: 'You did not provide a proper phone number.' });
        return;
      }

      setLoading(true);
      await createUser({
        variables: { firstName, lastName, phone: mobileNumber, email }
      });
      setLoading(false);
  };


  /** Hook function that starts a challenge if all things pass  */
  const startChallenge = async ({ method, reissue, recipient }: StartChallengeFields) => {
    try {
      setLoading(true);

      if (method && recipient){
        await updateAuthFlow({
          variables: {
            recipient,
            method
          }
        });
      }

      if (method === ChallengeMethods.Phone && recipient) {
        const mobileNumber = formatPhone(recipient);
        if (mobileNumber && isValidInput(method, recipient)) {
          await challengeUser({
            variables: { method, phone: mobileNumber, reissue }
          });
        } else {
          await setMessage({
            code: 'InputValidation',
            content: 'You did not provide a proper phone number.'
          });
        }
      }

      if (method === ChallengeMethods.Email && recipient) {
        if (recipient && isValidInput(method, recipient)) {
          await challengeUser({
            variables: { method, email: recipient, reissue }
          });
        } else {
          await setMessage({
            code: 'InputValidation',
            content: 'You did not provide a proper email'
          });
        }
      }
      setLoading(false);
    } catch (err) {
      await setMessage({
        code: err.code,
        color: 'danger',
        content: err.message
      });
      setLoading(false);
    }
  };

  /** Hook function that starts a challenge if all things pass  */
  const verifyChallenge = async ({ userId, challengeId, answer }: VerifyChallengeFields) => {
    try {
      setLoading(true);
      if (userId && challengeId && answer && answer.length === 6) {
        await verifyUser({
          variables: { userId, challengeId, answer }
        });
      } else {
        await setMessage({
          code: 'InputValidation',
          color: 'danger',
          content: 'We do not appear to have all the information we need.'
        });
      }
      setLoading(false);
    } catch (err) {
      await setMessage({
        code: err.code,
        color: 'danger',
        content: err.message
      });
      setLoading(false);
    }
  };

  /** Hook function that clears all challenge data */
  const endChallenge = async() => {
    setLoading(true);
    // Set reissue to true so user returns to challenge page
    await updateAuthFlow({
      variables: {
        step: AuthSteps.ChallengeUser,
        userId: '',
        challengeId: '',
        reissue: false
      }
    });
    setLoading(false);
  };

  /** Hook function that switches the active method */
  const switchMethod = async(m: ChallengeMethods) => {
    setLoading(true);
    // Set reissue to true so user returns to challenge page
    await updateAuthFlow({
      variables: {
        step: AuthSteps.ChallengeUser,
        method: m,
        recipient: null
      }
    });
    setLoading(false);
  };

  const logoutUser = async() => client.resetStore();

  return {
    logoutUser,
    registerUser,
    switchMethod,
    startChallenge,
    verifyChallenge,
    endChallenge,
    isValidInput,
    formatRecipient,
    data,
    loading
  };
};

export default useAuthFlow;
