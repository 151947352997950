import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { close } from 'ionicons/icons';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import NewRequestForm from 'containers/NewRequestForm';

const NewRequest: React.FC<RouteComponentProps> = ({
  history,
  location: { pathname, search },
  match: { url }
}) => {
  const params = qs.parse(search, { ignoreQueryPrefix: true });
  const [showModal, setShowModal] = useState(false);

  const cancel = async () => {
    history.replace('/app/home');
  };

  useEffect(() => {
    pathname === url ? setShowModal(true) : setShowModal(false);
  }, [pathname, url]);

  return (
    <IonModal isOpen={showModal} backdropDismiss={false}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>New Request</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={cancel}>
              <IonIcon icon={close} size="large" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="background">
        <NewRequestForm
          onSubmit={(id: string) => history.replace(`/app/requests/details/${id}`)}
          category={params.category}
        />
      </IonContent>
    </IonModal>
  );
};

export default NewRequest;
