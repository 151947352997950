import { IonContent, IonLoading, IonPage } from '@ionic/react';
import Form from 'components/Form';
import PhoneInput from 'components/PhoneInput';
import MessagesDisplay from 'containers/MessagesDisplay';
import useAuthFlow, { ChallengeMethods } from 'hooks/useAuthFlow';
import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

const PhoneChallenge: React.FC<RouteComponentProps> = ({ location: { search } }) => {
  const params = qs.parse(search, { ignoreQueryPrefix: true });
  const { startChallenge, isValidInput, data, loading, switchMethod } = useAuthFlow();
  const { recipient, reissue } = data;
  const [phone, setPhone] = useState(params.phone || recipient || '');
  const [validated, setValidated] = useState<boolean>(false);

  const checkValidation = useCallback(
    () => phone && setValidated(isValidInput(ChallengeMethods.Phone, phone)),
    [phone, setValidated, isValidInput]
  );
  const handleNext = useCallback(() => {
    if (validated && phone) {
      startChallenge({ method: ChallengeMethods.Phone, recipient: phone, reissue });
    }
  }, [validated, phone, reissue, startChallenge]);

  /** Responsible for checking the validity of phone input */
  useEffect(() => {
    checkValidation();
  }, [checkValidation]);

  const subtitle = (
    <React.Fragment>
      <p style={{ lineHeight: 1.5 }}>
        Provide your <strong>phone number</strong> to receive an SMS message with your One-Time
        Passcode
      </p>
      <p>Message and data rates may apply</p>
    </React.Fragment>
  );

  const buttons = [
    {
      text: 'Next',
      handler: handleNext,
      isDisabled: !validated
    },
    {
      text: 'Sign in with email instead?',
      color: 'tertiary',
      primary: false,
      handler: () => switchMethod(ChallengeMethods.Email)
    }
  ];

  return (
    <IonPage>
      <IonContent color="background">
        <IonLoading isOpen={loading} duration={10000} spinner="crescent" message="One Moment..." />
        <MessagesDisplay />
        <Form title="Welcome to P3 Works" subtitle={subtitle} buttons={buttons}>
          <PhoneInput value={phone} onChange={setPhone} clearInput autofocus />
        </Form>
      </IonContent>
    </IonPage>
  );
};

export default PhoneChallenge;
