import { IonCard, IonCol, IonGrid, IonRow } from '@ionic/react';
import SectionTitle from 'components/SectionTitle';
import Stat from 'components/Stat';
import { useRequests } from 'hooks/useRequests';
import { RequestStatus } from 'hooks/useStatus';
import React from 'react';

const RequestStatsCard: React.FC = () => {
  const { requests } = useRequests();

  const stats = requests.reduce(
    (acc: any, { status }: any) => ({ ...acc, [status]: acc[status] + 1 }),
    {
      [RequestStatus.Submitted]: 0,
      [RequestStatus.Approved]: 0,
      [RequestStatus.Denied]: 0,
      [RequestStatus.Completed]: 0
    }
  );

  return (
    <IonCard color="white">
      <SectionTitle title="Requests by Status" color="white" />
      <IonGrid className="ion-no-margin ion-no-padding">
        <IonRow className="ion-no-margin ion-no-padding">
          {Object.keys(stats).map((key: any) => (
            <IonCol size="6" key={key} className="ion-no-margin ion-no-padding">
              <Stat status={key} count={stats[key]} />
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

export default RequestStatsCard;
