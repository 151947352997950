import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import { IStatus, RequestStatus, useStatus } from 'hooks/useStatus';
import moment from 'moment';
import React, { HTMLAttributes } from 'react';
import { createUseStyles } from 'react-jss';

interface IStatusItem extends HTMLAttributes<HTMLIonItemElement> {
  status: RequestStatus;
  timestamp?: number;
}

const useStyles = createUseStyles({
  label: {
    fontWeight: 'bold !important',
    color: 'var(--ion-color-primary) !important'
  },
  timestamp: {
    color: 'var(--ion-color-dark) !important',
  }
});

const StatusItem = ({
  status,
  timestamp,
  ...htmlAttributes
}: IStatusItem) => {
  const classes = useStyles();
  const { color, label, icon }: IStatus = useStatus(status);
  return (
    <IonItem lines="none" {...htmlAttributes}>
      <IonIcon icon={icon} slot="start" color={color} size="large" />
      <IonLabel className="ion-text-wrap">
        <h4 className={classes.label}>{label}</h4>
        {timestamp && (
          <p className={classes.timestamp}> {moment(timestamp).fromNow()} </p>
        )}
      </IonLabel>
    </IonItem>
  );
};

export default StatusItem;
