import { Plugins } from '@capacitor/core';
import { useCallback, useEffect, useState } from 'react';
import { GeocoderService } from 'services/GeocoderService';

interface UseLocationOptions {
  autoFetch?: boolean;
}

export interface Location {
  latitude?: number;
  longitude?: number;
  address?: string;
}

const defaultState = {
  address: undefined,
  latitude: undefined,
  longitude: undefined
};

const defaultOptions = {
  autoFetch: false
};

export const parseAddress = (input: string) => {
  if (!input || input.length === 0) return input;
  const regex = /([^,]+)/gi;
  const found = input.match(regex);
  return found && found[0] ? found[0] : input;
};

export const isValidAddress = (address: string) => {
  const regex = /\d+[ ](?:[A-Za-z0-9.-]+[ ]?)+(?:Avenue|Lane|Road|Boulevard|Drive|Street|Ave|Dr|Rd|Blvd|Ln|St)\.?/g;
  return address.length > 0 && regex.test(address);
};

export const useLocation = (options?: UseLocationOptions) => {
  const { autoFetch } = options || defaultOptions;
  const [location, setLocation] = useState<Location>(defaultState);
  const [isFetchingLocation, setFetchingLocation] = useState(false);

  const updateLocation = (input: Location) => setLocation({ ...location, ...input });

  const resetLocation = () => setLocation(defaultState);

  const reverseGeocode = async (longitude: number, latitude: number): Promise<Location> => {
    try {
      const geocoder = new GeocoderService();
      const geocode = await geocoder.reverse(longitude, latitude);
      return {
        latitude,
        longitude,
        address: geocode.address || 'Address Not Available'
      };
    } catch (err) {
      throw err;
    }
  };

  const forwardGeocode = async (query: string): Promise<Location[]> => {
    try {
      const geocoder = new GeocoderService();
      const items = await geocoder.forward(query);
      return items;
    } catch (err) {
      throw err;
    }
  };

  const fetchCurrentLocation = async (): Promise<Location> => {
    try {
      setFetchingLocation(true);
      const { Geolocation } = Plugins;
      const position = await Geolocation.getCurrentPosition();
      const { latitude, longitude } = position.coords;
      const result: Location = await reverseGeocode(longitude, latitude);
      setFetchingLocation(false);
      return result;
    } catch (err) {
      setFetchingLocation(false);
      throw err;
    }
  };

  const autoFetchLocation = useCallback(async () => {
    if (autoFetch) {
      const current = await fetchCurrentLocation();
      setLocation(current);
    }
  }, [autoFetch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    autoFetchLocation();
  }, [autoFetchLocation]);

  return {
    forwardGeocode,
    reverseGeocode,
    updateLocation,
    resetLocation,
    fetchCurrentLocation,
    isFetchingLocation,
    location
  };
};

export default useLocation;
