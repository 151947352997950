import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLoading,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  IonToolbar
} from '@ionic/react';
import ActivityFeedSkeleton from 'components/ActivityFeedSkeleton';
import CommentManager from 'components/CommentManager';
import StatusItem from 'components/StatusItem';
import StatusItemSkeleton from 'components/StatusItemSkeleton';
import MessagesDisplay from 'containers/MessagesDisplay';
import RequestActivityFeed from 'containers/RequestActivityFeed';
import useComments from 'hooks/useComments';
import { usePhotoGallery } from 'hooks/usePhotoGallery';
import useRequests from 'hooks/useRequests';
import { camera, close } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { RouteComponentProps } from 'react-router-dom';

const useStyles = createUseStyles({
  iconOverride: {
    '& ion-icon': {
      fontSize: 'var(--ion-icon-medium)'
    }
  },
  textContent: {
    paddingTop: 0,
    paddingBottom: '0.625em'
  },
  contentDivider: {
    '--background': 'var(--ion-color-background)'
  }
});

const RequestDetails: React.FC<RouteComponentProps> = ({
  history,
  location: { pathname },
  match: { params, url }
}) => {
  const { id }: any = params;

  const classes = useStyles();

  const contentRef = useRef<any>(null);
  const [showModal, setShowModal] = useState(false);

  const { findRequestById, refetch: refetchRequest, loading: requestLoading } = useRequests();
  const {
    comments,
    postComment,
    refetch: refetchComments,
    startPolling,
    stopPolling
  } = useComments({ requestId: id });
  const { photos, loading, selectPhoto, onInputSelect, refetch: refetchPhotos } = usePhotoGallery({
    requestId: id
  });

  const refresh = (event: CustomEvent<any>) => {
    refetchRequest()
      .then(() => refetchComments())
      .then(() => refetchPhotos())
      .then(() => event.detail.complete())
      .catch(() => event.detail.complete());
  };

  useEffect(() => {
    if (pathname === url) {
      setShowModal(true);
      startPolling();
    } else {
      setShowModal(false);
      stopPolling();
    }
  }, [pathname, url, startPolling, stopPolling]);

  const request = findRequestById(id);
  if (!request) refetchRequest();

  const closeModal = () => {
    history.replace('/app/requests');
  };

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop(500);
  };

  return (
    <IonModal isOpen={showModal} backdropDismiss={false}>
      <IonHeader>
        <IonToolbar className={classes.iconOverride}>
          {!requestLoading && request ? (
            <StatusItem
              status={request.status}
              timestamp={request.modifiedAt}
              onClick={scrollToTop}
            />
          ) : (
            <StatusItemSkeleton />
          )}
          <IonButtons slot="end">
            <IonButton onClick={closeModal}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="background" ref={contentRef}>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent />
        </IonRefresher>
        <IonLoading isOpen={loading} duration={10000} spinner="crescent" message="One Moment..." />
        <MessagesDisplay />
        {!requestLoading && request ? (
          <RequestActivityFeed request={request} comments={comments} photos={photos} />
        ) : (
          <ActivityFeedSkeleton />
        )}
      </IonContent>

      <IonFooter>
        <IonToolbar className={classes.iconOverride}>
          <IonButtons slot="start">
            <IonButton onClick={selectPhoto}>
              <IonIcon icon={camera} />
            </IonButton>
          </IonButtons>
          <CommentManager postComment={postComment} />
          <input
            type="file"
            accept="image/x-png,image/jpeg,image/gif"
            onChange={onInputSelect}
            multiple
            style={{ display: 'none' }}
          />
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default RequestDetails;
