import { IonCard, IonIcon, IonItem, IonLabel, IonThumbnail } from '@ionic/react';
import StaticMap from 'components/StaticMap';
import { IStatus, RequestStatus, useStatus } from 'hooks/useStatus';
import moment from 'moment';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Truncate from 'react-truncate';

type IRequestItem = {
  category: string;
  description: string;
  modifiedAt: number;
  status: RequestStatus;
  longitude: number;
  latitude: number;
  address: string;
  onClick?: any;
};

const useStyles = createUseStyles({
  card: {
    '& ion-item:first-child': {
      '--inner-padding-end': 0,
      '& ion-thumbnail': {
        margin: 0,
        width: '100px',
        height: '100%'
      },
      '& ion-label.md': {
        marginRight: 10
      }
    },
    '& ion-item:last-child': {
      borderTop: '1px solid var(--ion-color-light)',
      '& ion-icon': {
        'margin-inline-end': '5px'
      }
    }
  },
  category: {
    fontSize: '1.25em !important',
    color: 'var(--ion-color-primary) !important',
    fontWeight: 'bold !important'
  },
  address: {
    color: 'var(--ion-color-primary) !important'
  },
  description: {
    minHeight: 40,
    fontSize: '0.825em !important',
    color: 'var(--ion-color-dark) !important',
    padding: {
      top: 10
    }
  }
});

const RequestItem = ({
  category,
  description,
  status,
  modifiedAt,
  latitude,
  longitude,
  address,
  onClick
}: IRequestItem) => {
  const { color, label, icon }: IStatus = useStatus(status);
  const classes = useStyles();

  const parseAddress = (input: string) => {
    const regex = /([^,]+)/gi;
    const found = input.match(regex);
    return found && found[0] ? found[0] : input;
  };

  return (
    <IonCard onClick={onClick} button color="white" className={classes.card}>
      <IonItem lines="none">
        <IonLabel className="ion-text-wrap">
          <h3 className={classes.category}>{`${category} Issue`}</h3>
          <p className={classes.address}>{parseAddress(address)}</p>
          <p className={classes.description}>
            <Truncate lines={2} ellipsis={<span>...</span>}>
            {description}
            </Truncate>
          </p>
        </IonLabel>

        <IonThumbnail slot="end">
          <StaticMap latitude={latitude} longitude={longitude} height={100} zoom={14} />
        </IonThumbnail>
      </IonItem>
      <IonItem lines="none">
        <IonIcon icon={icon} slot="start" color={color} />
        <IonLabel color="medium">
          <p>
            {label} - {moment(modifiedAt).fromNow()}
          </p>
        </IonLabel>
      </IonItem>
    </IonCard>
  );
};

export default RequestItem;
