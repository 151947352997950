import { IonIcon, IonItem, IonLabel, IonSkeletonText, IonThumbnail } from '@ionic/react';
import { navigateCircle } from 'ionicons/icons';
import React from 'react';

import StaticMap from './StaticMap';

type ILocationItemProps = {
  address?: string;
  latitude?: number;
  longitude?: number;
  onClick?: any;
  loading?: boolean;
};

const LocationItem = ({
  address = 'Unable to determine your location. Please click to select a location from the map.',
  latitude,
  longitude,
  onClick,
  loading = false
}: ILocationItemProps) => {
  if (loading) {
    return (
      <IonItem className="ion-no-padding" lines="none" detail={false}>
        <IonThumbnail>
          <IonSkeletonText animated />
        </IonThumbnail>
        <IonLabel className="ion-padding-horizontal" color="dark">
          <p>
            <IonSkeletonText animated style={{ width: '100%' }} />
            <IonSkeletonText animated style={{ width: '70%' }} />
          </p>
        </IonLabel>
      </IonItem>
    );
  }

  const LocationGraphic = () =>
    latitude && longitude ? (
      <IonThumbnail slot="start" style={{ marginInlineEnd: 0 }}>
        <StaticMap
          latitude={latitude}
          longitude={longitude}
          height={56 * 1.5}
          width={56 * 1.5}
          zoom={14}
        />
      </IonThumbnail>
    ) : (
      <IonIcon icon={navigateCircle} size="large" />
    );

  return (
    <IonItem className="ion-no-padding" lines="none" detail={true} onClick={onClick}>
      <LocationGraphic />
      <IonLabel className="ion-padding-horizontal ion-text-wrap" color="dark">
        <p>{address}</p>
      </IonLabel>
    </IonItem>
  );
};

export default LocationItem;
