import { JSX } from '@ionic/core';
import { IonInput, IonItem, IonLabel } from '@ionic/react';
import { AsYouType } from 'libphonenumber-js';
import React from 'react';
import { Rifm } from 'rifm';

interface PhoneInputProps {
  value: string;
  onChange: (arg0: any) => void;
  label?: string;
}

const parseDigits = (s: string) => (s.match(/\d+/g) || []).join('');
const formatPhone = (s: string) => {
  const digits = parseDigits(s).substring(0, 12);
  return new AsYouType('US').input(digits);
};

const PhoneInput: React.FC<PhoneInputProps & JSX.IonInput> = ({
  onChange,
  value,
  label,
  ...inputProps
}) => {

  return (
    <Rifm
      accept={/\d+/g}
      // do not jump after ) until see number before
      mask={
        value.length < 6 && /[^\d]+/.test(value[3])
          ? undefined
          : value.length >= 16
      }
      format={formatPhone}
      value={value}
      onChange={onChange}
    >
      {({ onChange: x, value: y}: any) => (
        <IonItem lines="none">
            {label && <IonLabel position="stacked" color="dark">{label}</IonLabel>}
            <IonInput
              {...inputProps}
              inputmode="tel"
              type="tel"
              value={y}
              onIonChange={x}
              placeholder="(999) 999-9999"
            />
        </IonItem>
      )}
    </Rifm>
  );
};

export default PhoneInput;
