import { Plugins, PushNotification, PushNotificationToken } from '@capacitor/core';
import { isPlatform } from '@ionic/core';
import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';
import { logEvent, logException } from 'services/logger';

const { PushNotifications } = Plugins;

const isPushSupported = isPlatform('capacitor') && (isPlatform('ios') || isPlatform('android'));

export const REGISTER_DEVICE = gql`
  mutation RegisterDeviceForAlerts($platform: String!, $token: String!) {
    registerDeviceForAlerts(input: { platform: $platform, token: $token }) {
      success
    }
  }
`;

export const initPush = async (apolloClient: ApolloClient<unknown>) => {
  if (!isPushSupported) return;

  const permissions = await PushNotifications.requestPermission();

  if (!permissions.granted) {
    logEvent('Push notification permissions not allowed by user (iOS)');
    return;
  }

  PushNotifications.addListener('registration', ({ value: token }: PushNotificationToken) => {
    let platform: 'iOS' | 'Android';
    if (isPlatform('ios')) platform = 'iOS';
    else if (isPlatform('android')) platform = 'Android';
    else return; // Only iOS and Android supported

    apolloClient
      .mutate({ mutation: REGISTER_DEVICE, variables: { platform, token } })
      .catch(logException);
  });

  PushNotifications.addListener('registrationError', logException);

  PushNotifications.register();
};

export const onPushNotificationReceived = (callback: (notification: PushNotification) => void) => {
  isPushSupported && PushNotifications.addListener('pushNotificationReceived', callback);
};

export const removedDeliveredNotifications = async () => {
  isPushSupported && PushNotifications.removeAllDeliveredNotifications();
};
