import {
  IonButton,
  IonCard,
  IonCardContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLoading,
  IonModal,
  IonTextarea
} from '@ionic/react';
import InteractiveMap from 'components/InteractiveMap';
import LocationItem from 'components/LocationItem';
import SectionTitle from 'components/SectionTitle';
import MapSearchToolbar from 'containers/MapSearchToolbar';
import { RequestCategory } from 'hooks/useCategories';
import useLocation, { Location, parseAddress } from 'hooks/useLocation';
import useRequests from 'hooks/useRequests';
import { arrowForward, send } from 'ionicons/icons';
import React, { useCallback, useEffect, useState } from 'react';

export interface INewRequestFormPros {
  onSubmit?: (arg0: string) => any;
  category?: RequestCategory;
}

const NewRequestForm: React.FC<INewRequestFormPros> = ({
  onSubmit,
  category = RequestCategory.Other
}) => {
  // Local state
  const [isMapOpen, displayMap] = useState(false);
  const [isValidated, setValidated] = useState(false);
  const [description, setDescription] = useState('');
  // Hooks
  const { submitRequest, createRequestLoading } = useRequests();
  const { location, isFetchingLocation, reverseGeocode, updateLocation } = useLocation({
    autoFetch: true
  });
  const { address, latitude, longitude } = location;

  // Handles the validation logic of the data
  const checkValidation = useCallback(() => {
    const conditions = [];

    conditions.push(() => description && description.length >= 5);
    conditions.push(() => category && category.length >= 3);
    conditions.push(() => location && location.latitude && location.longitude && location.address);

    const result = conditions.every((condition: any) => condition());
    setValidated(result);
  }, [category, description, location]);

  useEffect(() => {
    checkValidation();
  }, [checkValidation]);

  // Fires when user selects a map search item
  const onSearch = async (result: Location) => updateLocation(result);
  // Fires when user selects location on the map
  const onMapChange = async (lng: number, lat: number) => {
    const result = await reverseGeocode(lng, lat);
    updateLocation(result);
  };

  const submit = async (e: any) => {
    e.preventDefault();
    // Build request from local state and location
    const record = await submitRequest({ category, description, ...location });
    if (record && record.id) {
      // Clear the local states
      if (onSubmit) {
        onSubmit(record.id);
      }
    }
  };

  return (
    <React.Fragment>
      <IonLoading
        isOpen={createRequestLoading}
        duration={10000}
        spinner="crescent"
        message="One Moment..."
      />
      <form onSubmit={submit}>
        <IonCard color="white">
          <SectionTitle
            title={`${category} Issue`}
            subtitle="Please provide a location and description to submit this request."
          />
          <IonCardContent>
            <LocationItem
              address={address && parseAddress(address)}
              latitude={latitude}
              longitude={longitude}
              loading={isFetchingLocation}
              onClick={() => displayMap(true)}
            />
            <div className="ion-margin-vertical">
              <IonTextarea
                autoGrow={true}
                debounce={500}
                required={true}
                spellcheck={true}
                rows={1}
                name="description"
                value={description}
                onIonChange={(e: any) => setDescription(e.target.value)}
                placeholder="Please describe your issue."
              />
            </div>
          </IonCardContent>

          <IonButton
            expand="full"
            size="large"
            className="ion-no-margin"
            disabled={!isValidated || createRequestLoading}
            type="submit"
          >
            Submit Request
            <IonIcon slot="end" icon={send} />
          </IonButton>
        </IonCard>

        <IonModal isOpen={isMapOpen}>
          <IonHeader color="primary">
            <MapSearchToolbar onSelectedLocation={onSearch} />
          </IonHeader>
          <InteractiveMap
            popupText={
              address ? address : 'Use the address search above or drag the map to set a location'
            }
            latitude={latitude}
            longitude={longitude}
            height={'100%'}
            onSelectedLocation={onMapChange}
            moveable={true}
            enableGeolocate={true}
          />
          <IonFooter>
            <IonButton
              color="tertiary"
              expand="full"
              size="large"
              className="ion-no-margin"
              onClick={() => displayMap(false)}
            >
              Confirm Location
              <IonIcon slot="end" icon={arrowForward} />
            </IonButton>
          </IonFooter>
        </IonModal>
      </form>
    </React.Fragment>
  );
};

export default NewRequestForm;
