import { IonImg } from '@ionic/react';
import React from 'react';

const { REACT_APP_MAPBOX_TOKEN = '' } = process.env;

type IStaticMap = {
  latitude: number;
  longitude: number;
  zoom?: number;
  bearing?: number;
  pitch?: number;
  width?: number;
  height?: number;
  autoSize?: boolean;
};

const StaticMap = React.memo(
  ({
    latitude,
    longitude,
    zoom = 16,
    bearing = 0,
    pitch = 0,
    width = 320,
    height = 200,
    autoSize = false
  }: IStaticMap) => {
    const view = autoSize ? `auto` : `${longitude},${latitude},${zoom},${bearing},${pitch}`;
    const src = `https://api.mapbox.com/styles/v1/mapbox/light-v10/static/pin-l-star+cc003d(${longitude},${latitude})/${view}/${width}x${height}@2x?access_token=${REACT_APP_MAPBOX_TOKEN}&attribution=false&logo=false`;
    return <IonImg src={src} style={{ objectFit: 'cover' }} />;
  }
);

export default StaticMap;
