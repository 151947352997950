import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import useMessage from './useMessage';

export const GET_COMMENTS = gql`
  query GetComments($requestId: ID!) {
    request(id: $requestId) {
      id
      comments {
        id
        author
        content
        createdAt
      }
    }
  }
`;

/**
 * TODO: Mutation should also update comment author
 */
export const POST_COMMENT = gql`
  mutation PostComment($content: String!, $requestId: ID!) {
    addRequestComment(input: { content: $content, requestId: $requestId }) {
      request {
        id
        comments {
          id
          author
          content
          createdAt
        }
      }
    }
  }
`;

export interface CommentsConfig {
  requestId: string;
}

export interface Comment {
  id: string;
  author: string;
  content: any;
  createdAt: number;
}

const useComments = ({ requestId }: CommentsConfig) => {
  const { setMessage } = useMessage();
  const { data, refetch, startPolling, stopPolling } = useQuery(GET_COMMENTS, {
    variables: { requestId }
  });
  const [addRequestComment] = useMutation(POST_COMMENT);

  const comments: Comment[] = data && data.request ? data.request.comments : [];

  const postComment = async (content: string) => {
    try {
      const comment = await addRequestComment({ variables: { content, requestId } });
      return comment;
    } catch (err) {
      setMessage({ ...err, color: 'danger' });
    }
  };

  return {
    refetch,
    comments,
    postComment,
    startPolling: () => startPolling(20000),
    stopPolling
  };
};

export default useComments;
